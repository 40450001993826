
.fa-chevron-down:before { content: '\e800'; } /* '' */
.fa-chevron-up:before { content: '\e801'; } /* '' */
.fa-dot-3:before { content: '\e802'; } /* '' */
.fa-trash:before { content: '\e803'; } /* '' */
.fa-calendar-day:before { content: '\e804'; } /* '' */
.fa-xls:before { content: '\e805'; } /* '' */
.fa-ppt:before { content: '\e806'; } /* '' */
.fa-png:before { content: '\e807'; } /* '' */
.fa-circle-info:before { content: '\e808'; } /* '' */
.fa-edit:before { content: '\e809'; } /* '' */
.fa-login:before { content: '\e80a'; } /* '' */
.fa-logout:before { content: '\e80b'; } /* '' */
.fa-maximize:before { content: '\e80c'; } /* '' */
.fa-sort-up:before { content: '\e80d'; } /* '' */
.fa-notes:before { content: '\e80e'; } /* '' */
.fa-pdf:before { content: '\e823'; } /* '' */
.fa-heartbeat:before { content: '\e828'; } /* '' */
.fa-clock:before { content: '\e829'; } /* '' */
.fa-external-link:before { content: '\e82b'; } /* '' */
.fa-hand-paper:before { content: '\e830'; } /* '' */
.fa-cart:before { content: '\e831'; } /* '' */
.fa-cart-plus:before { content: '\e832'; } /* '' */
.fa-cart-minus:before { content: '\e833'; } /* '' */
.fa-spin:before { content: '\e834'; } /* '' */
.fa-circle-minus-filled:before { content: '\e835'; } /* '' */
.fa-video-player:before { content: '\e836'; } /* '' */
.fa-graduation:before { content: '\e837'; } /* '' */
.fa-calendar-plus:before { content: '\e838'; } /* '' */
.fa-calendar-time:before { content: '\e839'; } /* '' */
.fa-eye:before { content: '\e83a'; } /* '' */
.fa-address-card:before { content: '\e83b'; } /* '' */
.fa-invoice:before { content: '\e83c'; } /* '' */
.fa-calendar-search:before { content: '\e83d'; } /* '' */
.fa-download:before { content: '\e83e'; } /* '' */
.fa-upload:before { content: '\e83f'; } /* '' */
.fa-txt:before { content: '\e840'; } /* '' */
.fa-doc:before { content: '\e841'; } /* '' */
.fa-check:before { content: '\e842'; } /* '' */
.fa-jpg:before { content: '\e843'; } /* '' */
.fa-circle-warning:before { content: '\e844'; } /* '' */
.fa-circle-question:before { content: '\e845'; } /* '' */
.fa-house:before { content: '\e846'; } /* '' */
.fa-times:before { content: '\e847'; } /* '' */
.fa-astronaut:before { content: '\e848'; } /* '' */
.fa-file:before { content: '\e849'; } /* '' */
.fa-innere:before { content: '\e84a'; } /* '' */
.fa-children:before { content: '\e84b'; } /* '' */
.fa-brain:before { content: '\e84c'; } /* '' */
.fa-double-chevron-left:before { content: '\e84d'; } /* '' */
.fa-double-chevron-up:before { content: '\e84e'; } /* '' */
.fa-double-chevron-right:before { content: '\e84f'; } /* '' */
.fa-double-chevron-down:before { content: '\e850'; } /* '' */
.fa-feedback:before { content: '\e851'; } /* '' */
.fa-arrows-up-down:before { content: '\e852'; } /* '' */
.fa-sort-down:before { content: '\e853'; } /* '' */
.fa-fax:before { content: '\e854'; } /* '' */
.fa-sort:before { content: '\e855'; } /* '' */
.fa-circle-plus-bold:before { content: '\e858'; } /* '' */
.fa-user:before { content: '\e85b'; } /* '' */
.fa-calendar:before { content: '\e85e'; } /* '' */
.fa-arrow-right:before { content: '\e900'; } /* '' */
.fa-bed:before { content: '\e901'; } /* '' */
.fa-bones:before { content: '\e902'; } /* '' */
.fa-car:before { content: '\e903'; } /* '' */
.fa-chevron-left:before { content: '\e904'; } /* '' */
.fa-chevron-right:before { content: '\e905'; } /* '' */
.fa-facebook:before { content: '\e907'; } /* '' */
.fa-intestine:before { content: '\e909'; } /* '' */
.fa-kirinus-figure:before { content: '\e90a'; } /* '' */
.fa-kirinus-figure-slim:before { content: '\e90b'; } /* '' */
.fa-linkedin:before { content: '\e90c'; } /* '' */
.fa-mail:before { content: '\e90d'; } /* '' */
.fa-menu:before { content: '\e90e'; } /* '' */
.fa-phone-mail:before { content: '\e90f'; } /* '' */
.fa-phone:before { content: '\e910'; } /* '' */
.fa-psych:before { content: '\e911'; } /* '' */
.fa-quote:before { content: '\e912'; } /* '' */
.fa-search:before { content: '\e913'; } /* '' */
.fa-spore:before { content: '\e914'; } /* '' */
.fa-spore-covid:before { content: '\e915'; } /* '' */
.fa-stethoscope:before { content: '\e916'; } /* '' */
.fa-twitter:before { content: '\e917'; } /* '' */
.fa-xing:before { content: '\e918'; } /* '' */
.fa-360:before { content: '\e919'; } /* '' */
.fa-circle-minus:before { content: '\e91a'; } /* '' */
.fa-circle-plus:before { content: '\e91b'; } /* '' */
.fa-close:before { content: '\e91c'; } /* '' */
.fa-location:before { content: '\e91d'; } /* '' */
.fa-play:before { content: '\e91e'; } /* '' */
.fa-kirinus-figure-medium:before { content: '\e920'; } /* '' */
.fa-circle-o:before { content: '\f10c'; } /* '' */
.fa-circle:before { content: '\f111'; } /* '' */
