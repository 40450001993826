.locations-catalog {
  $self: &;

  //@include media-breakpoint-down('sm') {
  //  display: flex;
  //  flex-direction: column;
  //
  //  & > :first-child {
  //    order: 2;
  //  }
  //
  //  & > :nth-child(2) {
  //    order: 1;
  //  }
  //
  //  & > :nth-child(3) {
  //    order: 3;
  //  }
  //}

  &__display-count {
    font-weight: $font-weight-bold;

    //@include media-breakpoint-between('xs', 'sm') {
    //  display: none;
    //}
  }

  &__sector-btn > div > div:first-child {
    line-height: 1;
  }

  &__sector,
  &__type {
    &-headline {
      @include font-size($font-size-2);
      margin-bottom: 1rem;

      font-weight: $font-weight-bold;

      //&__mobile {
      //  display: flex;
      //  align-items: center;
      //  margin-top: 1rem;
      //
      //  @include media-breakpoint-up('sm') {
      //    display: none;
      //  }
      //}
    }

    .btn {
      border: 1px solid transparent;
      color: $black;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

      position: relative;
      display: flex;
      align-items: center;
      padding-left: 2em;
      padding-right: 2em;

      &::before {
        transition: opacity 0.25s ease-in-out;

        content: '\e91c';
        font-family: fontello;
        position: absolute;
        right: 0.75rem;
        opacity: 0;
      }

      > div {
        transition: transform 0.25s ease-in-out;
        transform: translateX(0);
        will-change: transform;
      }

      &.active {
        > div {
          transform: translateX(-0.5rem);
        }

        &::before {
          opacity: 1;
        }
      }

      &:not(.active):not(:hover){
        background: $white;
      }

      .btn-outline-danger {
        background: #{map-get($theme-colors, 'danger')};
      }

      &:hover,
      &.active {
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0);

        .fa {
          color: white;
        }
      }

      .fa {
        color: map_get($colors, 'brand-copper');
      }
    }
  }

  &__sector-reset {
    .btn {
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;

      &:active {
        box-shadow: none;
      }

      .fa {
        transition: color .15s ease-in-out;
        vertical-align: -5%;
      }

      &:hover {
        background-color: $white;
        color: map_get($colors, 'brand-secondary');

        .fa {
          color: map_get($colors, 'brand-secondary');
        }
      }
    }
  }

  .row {
    @extend %row-items-full-height;

    transition: opacity 0.5s;

    &.locations-catalog__fade-out {
      opacity: 0;
    }
  }

  &__type {
    margin-top: 1.5rem;

    @include media-breakpoint-up('md') {
      margin-top: 0;
    }
  }

  &__boxes-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;

      margin-bottom: 4rem;
    }

    &-headline {
      @include font-size($h1-font-size);
      font-weight: $font-weight-light;

      flex-basis: 75%;

      @include media-breakpoint-up(xl) {
        flex-basis: auto;
      }

      @include media-breakpoint-up('md') {
        margin: 0;
      }
    }

    &-switch {
      flex-basis: 100%;
      flex-shrink: 0;
    }

    &-display-count {
      flex-basis: 100%;

      @include media-breakpoint-up(xl) {
        flex-basis: auto;
      }
    }
  }

  .leaf {
    height: 100%;

    --padding-y: 1.5rem;
    --padding-x: 1.5rem;

    @include media-breakpoint-between('xs', 'sm') {
      --padding-y: 0.8rem;
      --padding-x: 0.8rem;
    }

    @include media-breakpoint-up('md') {
      --padding-y: 2.613rem;
      --padding-x: 2.613rem;
    }

    h3,
    .h3 {

      @include font-size($font-size-2);
      margin-bottom: 0;

      @include media-breakpoint-up('sm') {
        @include font-size($font-size-3);
        margin-bottom: $headings-margin-bottom;
      }

      & + p {
        @include media-breakpoint-down('sm') {
          margin-top: $headings-margin-bottom;
        }
      }
    }

    p {
      @include media-breakpoint-down('sm') {
        display: none;
      }
    }

    .btn {
      width: 100%;

      @include media-breakpoint-down('sm') {
        font-size: $font-size-1;
        padding-left: 1em;
        padding-right: 1em
      }
    }

    @include media-breakpoint-down(md) {
      &__img img {
        height: px2rem(100);
      }
    }

    &__footer {
      @include media-breakpoint-down(sm) {
        --footer-space: 1rem;
      }
    }
  }

  &__map-wrapper {
    display: none;

    margin-top: map-get($spacers, 5);
    transition: opacity 0.5s;
    opacity: 1;

    #{$self}__map {
      min-height: 30rem;
      height: 50vh;

      width: 100%;
      border-radius: var(--theme_img-border-radius);

      @include media-breakpoint-up('xl') {
        min-height: 40rem;
      }

      @include media-breakpoint-up('xl') {
        min-height: 50rem;
      }
    }

    #{$self}__map-search {
      $search: &;

      $search-input-border-radius: 2.375rem;
      --search-input-border-radius: #{$search-input-border-radius};

      position: relative;
      margin-bottom: map-get($spacers, 5);

      &__input {
        width: 100%;

        background-color: #F2EDEC;
        align-items: center;
        border: none;
        border-radius: var(--search-input-border-radius) !important; // Important is needed because of form-group setting some to 0

        padding: 0.75rem 3.5rem 0.75rem 1.5rem;
        font-size: $font-size-2;
        font-weight: $font-weight-light;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-3;
        }

        &:active,
        &:focus {
          box-shadow: none;
        }
      }

      &__button {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        background: none;
        border: none;

        font-size: 1.25rem;

        transform: translateY(-50%);
      }

      &--places-visible {
        --search-input-border-radius: #{divide($search-input-border-radius, 1.45)} #{divide($search-input-border-radius, 1.45)} 0 0 ;
      }
    }

    &#{$self}__fade-out {
      opacity: 0;
    }
  }

  &--map-visible {
    #{$self}__map-wrapper {
      display: block;
    }

    #{$self}__items {
      display: none;
    }
  }
}
