.slider-with-filter {
  .leaf {
    height: 100%;

    @include headlines() {
      font-size: $font-size-3;
    }
  }

  .swiper {
    &-container {
      margin-top: var(--theme_outer-space);
      overflow: inherit;
    }
  }

  .swiper-slide {
    width: px2rem(300);
    height: auto;
    margin-right: var(--theme_outer-space);

    @include media-breakpoint-up(md) {
      margin-right: 2.5rem;
    }
  }

  .locations-catalog__sector {
    margin-top: 1.5rem;
  }

  .locations-catalog__sector-headline {
    font-weight: $font-weight-bold;
    line-height: $headings-line-height;
    display: block;
  }

  &--singular-filter {
    .locations-catalog__sector-btn {
      pointer-events: none;
    }
  }
}
