.news-container {
  .row {
    @extend %row-items-full-height;
  }

  h2,
  .h2 {
    font-weight: 300;
    font-size: $h1-font-size;
  }

  &__more-news {
    margin-top: 2.5rem;

    .btn {
      padding: 0;
      color: map_get($colors, 'brand-copper');

      &:focus,
      &:hover {
        color: map_get($colors, 'brand-petrol');
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
