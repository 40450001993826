.sectors-slider {
  --theme_swiper-arrow-size: #{$font-size-base * 1.75};
  --bs-gutter-x: 3rem;

  // Slider geht rechts über die Contentbreite hinaus
  &--overflow-size {
    .swiper-container {
      overflow: inherit;
    }
  }

  .leaf {
    height: 100%;
  }

  &__headline > :first-child {
    @include font-size($h1-font-size);

    font-weight: 300;
    margin-bottom: 0;
    color: map_get($colors, 'brand-copper');
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__content {
    position: relative;
    z-index: $z-index-3;
    //margin: 0 -1.5rem;
  }

  .swiper {
    &-container {
      max-width: calc(map_get($container-max-widths, 'xl') + 3rem);
      padding: 0.8rem 0;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    &-slide {
      height: auto;
      padding: 0.75rem;

      @include media-breakpoint-down(md) {
        width: calc(100% - 0.5rem) !important;
      }

      @include media-breakpoint-down(xl) {
        &:first-child {
          padding-left: 0;
        }
      }

      @include media-breakpoint-up(md) {
        padding: 1.5rem;
        width: calc(100% / 3 - 3rem);
      }
    }

    &-button-prev,
    &-button-next {
      --arrow-color: #{map-get($colors, 'brand-copper')};

      color: map_get($colors, 'brand-copper');
      text-shadow: none;
      width: var(--theme_swiper-arrow-width);
      height: var(--theme_swiper-arrow-width);
      font-size: 1.25rem;
      border: 2px solid var(--arrow-color);
      border-radius: 50%;
      bottom: -2rem;
      top: inherit;

      @include media-breakpoint-up(md) {
        border: none;
        height: 100%;
        font-size: var(--theme_swiper-arrow-size);

        &:after {
          content: '\e900';
        }

        &:hover,
        &:focus {
          color: $primary;
        }

        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }

    &-button-prev {
      right: 5rem;
      margin-left: auto;

      @include media-breakpoint-up(md) {
        right: inherit;
      }
    }

    &-button-next {
      right: 1.5rem;

      @include media-breakpoint-up(md) {
        right: 0;
      }
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl ~ .swiper-button-next {
    &:after {
      @include media-breakpoint-up(md) {
        transform: scaleX(-1);
      }
    }
  }
}

* + .sectors-slider__content {
  margin-top: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }
}

.content-section--fade-out {
  .sectors-slider {
    .swiper {
      &-button {
        &-prev,
        &-next {
          @include media-breakpoint-up(md) {
            color: map_get($colors, 'brand-copper');
            text-shadow: none;
            width: var(--theme_swiper-arrow-width);
            height: var(--theme_swiper-arrow-width);
            font-size: 1.25rem;
            border: 2px solid var(--arrow-color);
            border-radius: 50%;
            bottom: -2rem;
            top: inherit;
          }

          &:after {
            content: '\e905';
          }

          &:hover,
          &:focus {
            border-color: map-get($colors, 'brand-petrol');
            color: map-get($colors, 'brand-petrol');
          }
        }

        &-prev {
          &::after {
            transform: scaleX(-1);
          }

          right: inherit;
          left: calc(50% - var(--theme_swiper-arrow-width) - 0.5rem);
        }

        &-next {
          right: calc(50% - var(--theme_swiper-arrow-width) - 0.5rem);
        }

        &-disabled {
          opacity: 0.5;
        }
      }
    }
  }

  * + .sectors-slider__content {
    margin-bottom: 3rem;
  }
}
