@use 'sass:map';

.content-text {
  @extend .circle-list-container;

  &--has-background {
    position: relative;

    &:after {
      --content-text--background-x: calc(var(--theme_section-padding) * -1);
      --content-text--background-y: calc(var(--content-text--background-x) * 0.666);

      content: '';
      display: block;
      background-color: inherit;

      position: absolute;
      z-index: -1;

      top: var(--content-text--background-y);
      bottom: var(--content-text--background-y);
      left: var(--content-text--background-x);
      right: var(--content-text--background-x);
    }
  }
}
