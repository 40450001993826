@font-face {
  //font-family: 'ArialMTPro';
  font-family: 'Arial Pro';
  font-weight: 400;
  font-display: swap;
  src: url('fonts/MyFonts/ArialMTPro-Regular/font.woff2') format('woff2'),
    url('fonts/MyFonts/ArialMTPro-Regular/font.woff') format('woff');
}

@font-face {
  //font-family: 'ArialMTPro';
  font-family: 'Arial Pro';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('fonts/MyFonts/ArialMTPro-Italic/font.woff2') format('woff2'),
    url('fonts/MyFonts/ArialMTPro-Italic/font.woff') format('woff');
}

@font-face {
  //font-family: 'ArialMTPro';
  font-family: 'Arial Pro';
  font-weight: 700;
  font-display: swap;
  src: url('fonts/MyFonts/ArialMTPro-Bold/font.woff2') format('woff2'),
    url('fonts/MyFonts/ArialMTPro-Bold/font.woff') format('woff');
}

@font-face {
  //font-family: 'ArialMTPro';
  font-family: 'Arial Pro';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('fonts/MyFonts/ArialMTPro-BoldItalic/font.woff2') format('woff2'),
    url('fonts/MyFonts/ArialMTPro-BoldItalic/font.woff') format('woff');
}
