.content-with-sidebar {
  position: relative;

  &__aside {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-width: map_get($container-max-widths, 'xl');
    margin: auto;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity .5s;

    &-outer {
      width: 18rem;
      margin-left: auto;
    }

    &-inner {
      padding: var(--theme_section-padding) 0;
      pointer-events: auto;
      z-index: $z-index-2;
    }

    &-back {
      margin-top: calc(var(--theme_section-padding) * 0.5);

      .btn {
        width: 100%;
      }
    }

    &.is-hidden {
      opacity: 0;
    }
  }

  &__main {
    position: relative;
  }

  .content-section {
    .swiper-container {
      &:not(.swiper-container-initialized) {
        padding-bottom: var(--theme_leaf-shadow-size);
        margin-bottom: calc(var(--theme_leaf-shadow-size) * -1);
      }
    }
  }
}

@include media-breakpoint-down('xl') {
  .content-with-sidebar {
    &__aside {
      display: none;
    }

  }
}

.breadcrumb-container {
  & + .content-with-sidebar {

    .content-with-sidebar__aside-inner {
      padding-top: var(--theme_spacer-size);
    }
  }
}
