@mixin break-word() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

@mixin break-word-hyphens() {
  @include break-word();
  hyphens: auto;
}
