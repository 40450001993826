.page-header-slider {
  --text-color: #{$white};
  --heading-color: inherit;
  --heading-shadow: -1px -1px 1px rgba(0, 0, 0, #{divide(1, 16)}), 1px 1px 1px rgba(0, 0, 0, #{divide(6, 16)});
  --arrow-down-shadow: 0 1px 1px rgba(0, 0, 0, #{divide(6, 16)});
  --arrow-down-color: #{$white};
  --main-padding-y: calc(var(--theme_outer-space) * 2);
  --main-padding-x: var(--theme_outer-space);
  --main-width: #{percentage(divide(8, 24))};
  --overlay-size: #{px2rem(448)};
  --overlay-thickness: 4px;
  --image-height: 70%;


  @include media-breakpoint-up(md) {
    --main-padding-y: #{($font-size-base * 1.5)};
    --main-padding-x: 0;
    --image-height: 50%;
  }

  @include media-breakpoint-up(lg) {
    --main-padding-y: var(--theme_outer-space);
    --main-width: #{percentage(divide(16, 24))};
    --min-height: #{px2rem(520)};
    --image-height: 0;
  }

  @include media-breakpoint-up(xl) {
    --main-width: #{percentage(divide(8, 24))};
  }

  padding: 0 var(--theme_outer-space);

  & > :first-child {
    position: relative;
    width: 100%;
    max-width: map_get($container-deviant-sizes, 'xl');

    margin-left: auto;
    margin-right: auto;

    > :first-child {
      background-color: map_get($colors, 'brand-silver');
      border-radius: var(--theme_slider-border-radius);
      overflow: hidden;
    }
  }

  // slide
  .swiper-slide {
    align-items: center;
    height: auto;
    min-height: var(--min-height);
    text-align: center;

    @include media-breakpoint-up(lg) {
      display: grid;
      color: var(--text-color);
      text-align: left;
    }

    & > * {
      grid-area: 1 / 1 / 2 / 2;
    }

    &--no-gradient {
      .page-header-slider__image-container {
        &::after {
          content: none;
        }
      }
    }

    &--full-gradient {
      .page-header-slider__image-container {
        &::after {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }

    &--bottom-gradient {
      .page-header-slider__image-container {
        &::after {
          background: linear-gradient(0deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.55) 30%, rgba(0,0,0,0.4) 100%);
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &--center-text {
        --main-width: #{percentage(divide(12, 24))};

        .page-header-slider__inner {
          align-items: center;
          text-align: center;
        }
      }

      &--full-width {
        --main-width: 100%;
      }
    }
  }

  * + p {
    margin-top: 1rem;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base;
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;

    @include media-breakpoint-up(lg) {
      height: 100%;
      position: relative;
    }
  }


  &__image,
  &__video {
    padding-top: var(--image-height);
    position: relative;
  }

  &__image-container,
  &__image-container:after,
  &__image,
  &__video {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }

  &__image-container {
    background-color: rgba($black, divide(1, 8));

    &:after {
      content: '';
      right: auto;
      left: 0;
      width: 100%;
      background-image: linear-gradient(#{360deg * divide(5, 16)}, rgba($black, divide(5, 16)) 0%, transparent 50%);
      pointer-events: none;
    }
  }



  &__inner {
    position: relative;
    width: 100%;
    max-width: map_get($container-max-widths, 'xl');
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      padding: ($font-size-base * 2.75) var(--theme_outer-space) ($font-size-base * 1.75) var(--theme_outer-space);

      max-width: calc(map_get($container-max-widths, 'xl') + (var(--theme_outer-space) * 2));
    }
  }

  .swiper-container-initialized {
    .page-header-slider__inner {
      @include media-breakpoint-up(lg) {
        padding: ($font-size-base * 2.75) calc(var(--theme_outer-space) * 2) ($font-size-base * 1.75) calc(var(--theme_outer-space) * 2);

        max-width: calc(map_get($container-max-widths, 'xl') + (var(--theme_outer-space) * 4));
      }
    }
  }

  &__main {
    @include break-word();
    font-size: $font-size-4;
    padding: var(--main-padding-y) var(--main-padding-x);
    margin: auto 0;

    &:empty {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      width: var(--main-width);
    }
  }

  @include headlines() {
    @include font-size($font-size-5);

    color: var(--heading-color);
    font-weight: 700;

    @include media-breakpoint-up(xl) {
      @include font-size($font-size-6);
    }

    &:last-child {
      margin: 0;
    }
  }

  // Button
  .btn {
    margin-top: ($font-size-base * 0.5);

    @include media-breakpoint-up(lg) {
      margin-top: ($font-size-base * 2.75);
    }

    padding-top: 1rem * divide(7, 16);
    padding-bottom: 1rem * divide(8, 16);
  }

  // Arrow down
  &__down {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;

      position: absolute;
      left: 50%;
      bottom: 6rem;
    }

  }

  // Overlay
  &__overlay {
    position: absolute;
    top: 0;
    right: (-1em * divide(53, 128));
    bottom: 0;
    left: auto;
    z-index: $z-index-1;
    margin: auto;
    font-size: var(--overlay-size);
    width: auto;
    height: auto;
    display: none;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    svg {
      height: 0.9em;
      width: auto;
      stroke: map_get($colors, 'brand-silver');
      stroke-width: var(--overlay-thickness);
      opacity: divide(7, 16);
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      animation-name: fadeInRight;
      animation-duration: 3s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.18, 0.97, 0.64, 1);
      animation-play-state: paused;
    }
  }

  &__big-text {
    display: block;
    line-height: 1;

    & + & {
      margin-top: 1.2rem;
    }

    &--size-1 {
      @include font-size($font-size-2);
    }

    &--size-2 {
      @include font-size($font-size-6);
    }

    &--size-3 {
      @include font-size($font-size-7);
    }

    @include media-breakpoint-up(lg) {
      & + & {
        margin-top: 1.5rem;
      }

      &--size-2 + &--size-1 {
        margin-top: 2.5rem;
      }

      &--size-1 {
        @include font-size($font-size-5);
      }

      &--size-2 {
        @include font-size($font-size-8);
      }

      &--size-3 {
        @include font-size($font-size-9);
      }
    }

    @include media-breakpoint-up(xl) {
      &--size-1 {
        @include font-size($font-size-5);
      }

      &--size-2 {
        @include font-size($font-size-9);
      }

      &--size-3 {
        @include font-size($font-size-10 * 0.8);
      }
    }

    &--weight-light {
      font-weight: $font-weight-light;
    }

    &--wide {
      letter-spacing: 0.5rem;
    }
  }

  &--xl {
    --page-header-slider__height: calc(100vh - var(--theme_outer-space) - var(--theme_header-primary-height));

    @include media-breakpoint-up(lg) {
      --min-height: var(--page-header-slider__height);
    }

    @include media-breakpoint-up(lg) {
      --overlay-size: calc(var(--page-header-slider__height) * #{divide(600, 770)});
    }

    & > :first-child {
      max-width: 100%;

      padding-left: var(--theme_outer-space);
      padding-right: var(--theme_outer-space);
    }

    .page-header-slider {
      &__overlay {
        svg {
          opacity: divide(7, 16);
        }
      }
    }
  }

  &--lg {
    $slide-height--lg: map_get($container-deviant-sizes, 'xl') * divide(770, 1800);

    @include media-breakpoint-up(lg) {
      --min-height: #{$slide-height--lg};
    }

    @include media-breakpoint-up(lg) {
      --overlay-size: #{$slide-height--lg * divide(1025, 770)};
    }

    .page-header-slider {
      &__overlay {
        svg {
          opacity: divide(7, 16);
          transform: translateY(#{1em * divide(1, 16)});
        }
      }
    }
  }

  &--sm {
    $slide-height--sm: map_get($container-deviant-sizes, 'xl') * divide(336, 1800);

    --min-height: #{$slide-height--sm};
    --overlay-thickness: 8px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      --overlay-size: #{$slide-height--sm * divide(280, 336)};
    }

    .page-header-slider {
      &__overlay {
        svg {
          opacity: divide(4, 8);
        }
      }
    }
  }

  &--contrast {
    --text-color: #{$body-color};
    --heading-color: #{$primary};
    --heading-shadow: none;
    --arrow-down-color: #{$primary};
    --arrow-down-shadow: none;
    --main-width: #{percentage(divide(8, 24))};

    .page-header-slider {
      &__image-container {

        @include media-breakpoint-up(lg) {
          width: percentage(divide(9, 16));
        }

        &:after {
          display: none;
        }
      }

      &__footer {
        width: var(--main-width);
      }
    }
  }

  &--plate {
    --main-padding-y: #{($font-size-base * 3)};
    --main-padding-x: #{($font-size-base * 3)};
    --heading-shadow: none;

    .swiper-slide {
      color: $white;
    }

    .page-header-slider {
      &__image-container:after,
      &__footer,
      &__overlay {
        display: none;
      }

      &__inner {
        padding-top: 0;
        padding-bottom: 0;
      }

      &__main {
        width: 100%;
        max-width: calc(#{map_get($container-deviant-sizes, 'md')} + (var(--main-padding-x) * 2));
        margin: 0;
        background-color: map_get($colors, 'brand-copper');

        @include media-breakpoint-up(md) {
          margin: auto auto 0;
        }
      }
    }

    h1 {
      margin-bottom: 0;
    }
  }

  .swiper-button {
    &-prev,
    &-next {
      transition: height .25s;
    }
  }
}

:root.js {
  .page-header-slider {
    &__main,
    &__down {
      opacity: 0;
      pointer-events: none;
      transition: opacity .5s;
    }

    &--loaded {
      .page-header-slider {
        &__main,
        &__down {
          opacity: 1;
          pointer-events: auto;
        }

        &__overlay {
          animation-play-state: running;
        }
      }
    }

  }
}
