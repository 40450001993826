@use 'sass:math';

.scroll-indicator {
  $self: &;

  display: none;
  margin-left: -1rem;
  opacity: 0;

  transition: opacity 0.5s ease-in;
  transition-property: opacity, margin;

  @include media-breakpoint-up(md) {
    &--loaded {
      display: block;

      margin-left: 0;
      opacity: 1;
    }
  }

  z-index: $zindex-fixed;

  position: fixed;
  left: calc(var(--theme_outer-space) / 2);
  top: 50%;
  transform: translate(-50%, -50%);

  &__items {

    list-style: none;

    padding: 0;

    li {
      --circle-background: #{lighten(map-get($colors, 'brand-gray'), 33%)};

      button {
        @include reset-button();

        display: flex;
        flex-direction: row;
        align-items: center;

        outline: none;

        padding: 0 1rem;
        width: 2.5rem;

        &:before {
          content: '';
          display: block;
          width: 0.66rem;
          height: 0.66rem;
          background: var(--circle-background);

          border: 1px solid #{map-get($colors, 'brand-gray')};

          border-radius: 50%;
          flex-shrink: 0;
        }

        #{$self}__item-tooltip {
          opacity: 0;
          transition: opacity 0.25s ease-in-out;
        }

        &:hover,
        &:focus,
        &:active {
          outline: none;

          #{$self}__item-tooltip {
            opacity: 1;
          }
        }
      }

      &.active {
        --circle-background: #{map-get($colors, 'brand-secondary')};
      }
    }
  }

  &__item-tooltip {
    $padding-x: 1rem;
    $padding-y: 0.5rem;

    $tooltip-height: ($padding-y * 2 + $font-size-base);

    --background-color: #{transparentize(darken(#FFFFFF, 10%), 0.1)};

    display: block;
    pointer-events: none;

    position: relative;
    text-align: left;
    line-height: 1;

    padding: #{$padding-y} #{$padding-x};
    margin-left: divide($tooltip-height, 2);

    font-weight: 700;
    white-space: nowrap;

    opacity: 0;
    background: var(--background-color);
    filter: drop-shadow(0 0 var(--theme_leaf-shadow-size) rgba($black, divide(1, 3)));

    // Arrow
    &::before {
      content: '';
      display: block;

      position: absolute;
      left: divide(-$tooltip-height, 2);
      top: 50%;
      transform: translate(-50%, -50%);

      width: 0;
      height: 0;

      border: divide($tooltip-height, 2) solid transparent;
      border-right-color: var(--background-color);
    }
  }
}
