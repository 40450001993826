// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.outer-space {
  padding-right: var(--theme_outer-space);
  padding-left: var(--theme_outer-space);
}

.size-1 { font-size: $font-size-1 !important; }
.size-2 { font-size: $font-size-2 !important; }
.size-3 { font-size: $font-size-3 !important; }
.size-4 { font-size: $font-size-4 !important; }
.size-5 { font-size: $font-size-5 !important; }
.size-6 { font-size: $font-size-6 !important; }
.size-7 { font-size: $font-size-7 !important; }
.size-8 { font-size: $font-size-8 !important; }

.container-padding {
  padding-right: $container-padding-x;
  padding-left: $container-padding-x;
}

.reset-button {
  @include reset-button();
}

.reset-text {
  @include reset-text();
}

.inherit-text {
  @include inherit-text();
}

.break-word {
  @include break-word();
}

.break-word-hyphens {
  @include break-word-hyphens();
}

.no-mw {
  max-width: none !important;
}

.nobr {
  white-space: nowrap !important;
}

.object-position-bottom {
  object-position: bottom;
}

.object-position-top {
  object-position: top;
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {
}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {
}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {
}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {
}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {
}
