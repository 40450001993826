.slider-carousel {
  &__headline {
    margin-bottom: $spacer;

    & > * {
      font-size: $h1-font-size;
      font-weight: $font-weight-light;
      margin-bottom: calc(var(--theme_section-padding) / 2);
    }
  }

  .swiper {
    &-container {
      overflow: inherit;

      @media (min-width: 90rem) {
        width: calc(100% + var(--theme_overflow-size) * 2);
        max-width: calc(100% + var(--theme_overflow-size) * 2);
        margin-left: calc(var(--theme_overflow-size) * -1);
      }
    }

    &-slide {
      transition: opacity .5s;
      align-items: center;
      height: auto;
      margin-right: var(--theme_outer-space);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  figure {
    margin: 0;
  }

  figcaption {
    position: absolute;
    bottom: -3.5rem;
    background-color: rgba(207,203,202,0.76);
    border-top-right-radius: px2rem(25);
    color: $black;
    font-size: $font-size-base;
    padding: 1rem;
    max-width: px2rem(560);
    transition: bottom .5s;

    @media (min-width: 90rem) {
      margin: 0 var(--theme_overflow-size);
    }
  }

  &__image {
    position: relative;
    background-color: map_get($colors, 'brand-silver');
    height: px2rem(360);
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: px2rem(720);
    }
  }
}

:root.js {
  .slider-carousel {
    .swiper-container-initialized {
      .swiper {
        &-slide {
          opacity: 0.45;

          &-active {
            opacity: 1;

            figcaption {
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
