// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------

$limited-height-gradient-height: 50%;

// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.hr-list {
  --inner-padding-x: 0.75em;
  --inner-padding-y: 1em;
  --icon-area-width: 3em;

  --column-gap: #{$font-size-base * 3};

  @include break-word();
  margin: 0;
  padding: 1px 0 0 !important;
  list-style: none;

  > li {
    border: 0 solid #BFBFBF;
    border-top-width: 1px;
    border-bottom-width: 1px;
    margin-top: -1px !important;
    break-inside: avoid;
    padding: var(--inner-padding-y) var(--inner-padding-x) !important;

    &:before {
      content: '' !important;
      display: none;
    }

    // Add selector of element, that should be pulled to full cell size, to improve clickable area
    > a,
    > details > summary,
    > details > div {
      margin: calc(var(--inner-padding-y) * -1) calc(var(--inner-padding-x) * -1);
    }

    > a,
    > details > summary {
      display: flex;
      color: inherit;
      text-decoration: none;
      align-items: center;
      padding: var(--inner-padding-y) var(--inner-padding-x);

      &:after {
        @include fontello();
        content: '\e905'; // chevron-right
        margin-left: auto;
        transition: transform $beat;
      }

      &:hover,
      &:focus {
        background-image: linear-gradient(to right, transparent 0%, rgba($black, divide(1, 32)) #{percentage(divide(3, 8))});
        color: $primary;
        outline: none;
      }
    }

    > a {
      &:hover,
      &:focus {
        &:after {
          transform: translate3d(#{1em * divide(1, 4)}, 0, 0);
        }
      }
    }

    > details {
      > summary {
        &::-webkit-details-marker {
          display: none;
        }

        &:after {
          content: '\e905'; // chevron-right
          transform: rotate(90deg);
        }

        &:hover,
        &:focus {
          &:after {
            transform: translate3d(0, #{1em * divide(1, 4)}, 0) rotate(90deg);
          }
        }
      }

      > div {
        padding: var(--inner-padding-y) var(--icon-area-width) 1.5em var(--inner-padding-x);
        break-inside: avoid;

        p,
        ul,
        ol {
          font-size: inherit;
        }
      }

      &[open] {
        > summary {
          color: $primary;

          &:after {
            color: $body-color;
            transform: rotate(#{180deg + 90deg}) translate3d(0.025em, 0, 0);
          }

          &:hover,
          &:focus {
            background-image: none;

            &:after {
              color: inherit;
            }
          }
        }
      }
    }
  }

  &--sm {
    --inner-padding-y: 0.75em;

    font-size: $font-size-2;
    font-weight: $font-weight-normal;
  }

  &--split:not(:only-child) {
    width: calc(50% - var(--column-gap) / 2);

    //column-count: 2;
  }

  &-container {
    display: flex;
    flex-direction: column;

    > .hr-list {
      margin: 0;

      &:not(:first-child) {
        margin-top: -1px;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;

      > .hr-list {
        flex-basis: calc(50% - 1.5rem);
        flex-grow: 0;
        flex-shrink: 0;

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

  &--no-space-left {
    > li {
      padding-left: 0;
    }
  }
}

.big-leaf .hr-list {
  @extend .hr-list--sm;
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .hr-list {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .hr-list {
    &--split {
      column-count: 1;
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  %limited-height {
    position: relative;

    max-height: 20rem;
    overflow: hidden;

    &:only-child {
      height: 100%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;

      pointer-events: none;

      bottom: 0;
      left: 0;
      right: 0;

      height: $limited-height-gradient-height;

      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.66) 50%, rgba(255,255,255,1) 87%);
    }
  }

  .hr-list {
    $self: &;

    &--limited-height {
      @extend %limited-height;
    }

    &-container {
      &--limited-height {
        @extend %limited-height;
      }
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .hr-list {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .hr-list {
  }

}
