@font-face {
  font-family: 'fontello';
  src: url('fonts/fontello/kirinus.woff2') format('woff2'),
    url('fonts/fontello/kirinus.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes fa-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.fa {
  @include fontello();
  line-height: 1;

  &-lg {
    font-size: 1.33333333em;
    line-height: 0.75em;
    vertical-align: -15%;
  }

  #{"&-2x"} {
    font-size: 2em;
  }

  #{"&-3x"} {
    font-size: 3em;
  }

  #{"&-4x"} {
    font-size: 4em;
  }

  #{"&-5x"} {
    font-size: 5em;
  }

  &-fw {
    width: 1.28571429em;
    text-align: center;
  }

  &-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;

    > li {
      position: relative;
    }
  }

  &-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;

    &.fa-lg {
      left: -1.85714286em;
    }
  }

  &-border {
    padding: 0.2em 0.25em 0.15em;
    border: solid 0.08em #eeeeee;
    border-radius: 0.1em;
  }

  &-pull {
    &-left {
      float: left;
    }

    &-right {
      float: right;
    }
  }

  &,
  &.fa {
    &-pull {
      &-left {
        margin-right: 0.3em;
      }

      &-right {
        margin-left: 0.3em;
      }
    }
  }

  &-spin {
    animation: fa-spin 2s infinite linear;
  }

  &-pulse {
    animation: fa-pulse 1s infinite linear;
  }

  &-rotate {
    &-90 {
      transform: rotate(90deg);

      :root & {
        filter: none;
      }
    }

    &-180 {
      transform: rotate(180deg);

      :root & {
        filter: none;
      }
    }

    &-270 {
      transform: rotate(270deg);

      :root & {
        filter: none;
      }
    }
  }

  &-flip {
    &-horizontal {
      transform: scale(-1, 1);

      :root & {
        filter: none;
      }
    }

    &-vertical {
      transform: scale(1, -1);

      :root & {
        filter: none;
      }
    }
  }

  &-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;

    #{"&-1x"},
    #{"&-2x"} {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
    }

    #{"&-1x"} {
      line-height: inherit;
    }

    #{"&-2x"} {
      font-size: 2em;
    }
  }

  &-inverse {
    color: #ffffff;
  }
}

@mixin icon-normalize($increase-line-height: true) {
  .fa {
    &:before {
      @if $increase-line-height {
        line-height: 1.5;
      }
    }

    &:after {
      content: '.';
      letter-spacing: 0;
      width: 0;
      visibility: hidden;
    }

    &.fa-bed:before {
      font-size: 120%;
    }

    &.fa-car:before {
      font-size: 90%;
    }

    &.fa-location:before {
      font-size: 140%;
    }

    &.fa-mail:before {
      font-size: 80%;
    }

    &.fa-phone:before {
      font-size: 120%;
    }

    &.fa-feedback:before {
      font-size: 140%;
    }

    &.fa-fax:before {
      font-size: 120%;
    }
  }
}

@import 'build/kirinus-codes';
