.circle-list-container {
  ul {
    @extend .circle-list;
    @extend .circle-list--indented;

    margin-top: var(--theme_spacer-size);
    margin-bottom: var(--theme_spacer-size);
  }

  ol {
    li {
      padding-left: 0.5rem;

      + li {
        margin-top: 0.5em;
      }
    }
  }
}
