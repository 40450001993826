.image-gallery {
  display: grid;
  grid-gap: var(--theme_outer-space);

  figure {
    margin-bottom: 0;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: var(--theme_img-border-radius);
  }

  & + & {
    margin-top: var(--theme_outer-space);
  }

  @include media-breakpoint-up('md') {
    &--2,
    &--4 {
      grid-template-columns: 1fr 1fr;

      img {
        max-height: px2rem(500);
      }
    }

    &--3,
    &--5 {
      grid-template-columns: repeat(14, 1fr);

      $grid-items: ('img-1','img-2','img-3','img-4','img-5');

      @for $i from 1 through 5 {
        & > :nth-child(#{$i}) {
          grid-area: unquote(nth($grid-items, $i));
        }
      }
    }

    // Darstellung bei 3 Bilder
    &--3 {
      grid-template-rows: px2rem(252);
      grid-template-areas: 'img-1 img-1 img-1 img-1 img-2 img-2 img-2 img-2 img-2 img-2 img-3 img-3 img-3 img-3';
    }

    // Darstellung bei 5 Bilder
    &--5 {
      grid-template-rows: px2rem(400) px2rem(202.5);
      grid-template-areas: 'img-1 img-1 img-1 img-1 img-1 img-1 img-1 img-2 img-2 img-2 img-2 img-2 img-2 img-2'
                           'img-3 img-3 img-3 img-3 img-4 img-4 img-4 img-4 img-4 img-4 img-5 img-5 img-5 img-5';
    }
  }

  &--oversize {
    @include media-breakpoint-up(xl) {
      width: calc(100% + var(--theme_overflow-size) * 2);
      margin-left: calc(var(--theme_overflow-size) * -1);
    }
  }
}
