@use 'sass:math';
@use 'sass:map';

// Reference sizes from design
$reference-container-size: math.div(1320, 1500);

$tile-padding-base: px2rem(60 * $reference-container-size);
$gap-size: $tile-padding-base;
$tile-columns: 6;
$tile-min-height-sm: px2rem(400);
$tile-min-height: px2rem(535);
$tile-min-height-xl: px2rem(700);


.tile {
  $self: &;

  border-radius: var(--theme_leaf-border-radius);
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;

  padding: $tile-padding-base ($tile-padding-base + px2rem(10));
  box-shadow: 0 0 var(--theme_leaf-shadow-size) rgba($black, divide(1, 10));

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 0;

  min-height: $tile-min-height;

  &--xl {
    min-height: #{$tile-min-height-xl};
  }

  &--sm {
    min-height: #{$tile-min-height-sm};
  }

  &--full-height {
    min-height: 100%;
  }

  &--clickable {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    color: $white;
    font-size: 2.5rem;

    button {
      img,
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      svg {
        fill: $white;
      }
    }
  }

  &__toggle {
    @include reset-button;

    line-height: 1;
    transform: rotate(0deg);
    transform-origin: center;
    transition: transform 0.25s ease-in-out;
  }

  &__body {
    @include font-size($font-size-6 * 0.9);
    font-weight: $font-weight-bold;
    line-height: 1.125;

    color: $white;
    max-width: 750px;

    > p,
    a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    @include headlines {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  &__darken {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    pointer-events: none;
    height: 100%;
    opacity: 0;

    border-radius: var(--theme_leaf-border-radius);
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;

    z-index: -1;

    transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;

    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(59,58,58,0.5046393557422969) 70%, rgba(255,255,255,0) 100%);
  }

  &--full-gradient &__darken {
    background: rgb(0,0,0);
  }

  &__background {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: var(--theme_leaf-border-radius);
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;

    z-index: -2;

    overflow: hidden;

    > *,
    > picture > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    @include font-size($font-size-3);

    color: white;
    opacity: 0;
    transform: translateY(1rem);
    font-weight: $font-weight-light;
    line-height: 1.5;

    transition: transform 0.33s ease-in-out, opacity 0.33s ease-in-out;

    margin-top: map-get($spacers, 3);

    @include headlines {
      @include font-size($font-size-6);

      font-weight: $font-weight-light;
    }

    a {
      color: inherit;
    }

    li:before {
      color: inherit !important;
    }
  }

  &--open-inline {
    justify-content: flex-start;
  }

  &--open-inline &__darken {
    opacity: 0.75 !important; // Important is needed to override inline style

    background: rgb(0,0,0);
  }

  &--open-inline &__body {
    display: none;
  }

  &#{$self}--open#{$self}--clickable {
    cursor: default;
  }

  &--open &__toggle {
    transform: rotate(45deg);
  }

  #{$self}--open-inline#{$self}--overlay-open &__overlay {
    opacity: 1;
    transform: translateY(0);
  }
}

@include media-breakpoint-down(xl) {
  .tile {
    padding: ($tile-padding-base * 0.75)  (($tile-padding-base + px2rem(10)) * 0.75);

    &__body {
      @include font-size($font-size-5);
    }

    &--xl {
      min-height: $tile-min-height-xl * 0.66;
    }
  }
}

@include media-breakpoint-down(lg) {
  .tile-container {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(#{$tile-min-height * 0.75}, auto);
  }

  .tile {
    min-height: $tile-min-height;

    &--xl {
      min-height: $tile-min-height;
    }

    @for $i from 1 through $tile-columns {
      &--width-#{$i} {
        grid-column: span 1;
      }

      &--width-#{$i} &__body {
        max-width: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .tile {
    min-height: 45vh;

    &--xl {
      min-height: 45vh;
    }
  }
}
