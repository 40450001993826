.scroll-icon {
  width: 24px;
  height: 35px;
  box-sizing: border-box;
  border: 2px solid $white;
  border-radius: 12px;
  transform: translateX(-50%);
  z-index: 4;


  &__dot {
    width: 6px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 5px;
    transform: translate(-50%, 0);
    background-color: $white;
    border-radius: 3px;
    animation: dot-animation 1.25s infinite ease-out alternate;
  }

  @keyframes dot-animation {
    0% {
      transform: translate(-50%, 0);
    }
    100% {
      transform: translate(-50%, 15px);
    }
  }
}
