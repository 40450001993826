.counter-up-statistic {
  --inner-padding: 1rem;

  padding-top: 3rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up(md) {
    --inner-padding: 1.5rem;

    padding-top: 6rem;
    padding-bottom: 4.5rem;
  }

  header {
    @include font-size($font-size-6);
    text-align: center;
    color: map_get($colors, 'brand-primary');

    @include headlines() {
      font-weight: $font-weight-bold;
      font-size: inherit;
    }
  }

  i {
    margin-bottom: 1rem;
  }

  &__inner {
    text-align: center;

    span {
      display: block;
    }

    &-box {
      & > :first-child {
        padding: var(--inner-padding);
      }

      @include media-breakpoint-up(md) {
        &:not(:last-child) {
          background-image: linear-gradient(#d5d0cd, #d5d0cd);
          background-size: 2px 30%;
          background-repeat: no-repeat;
          background-position: right center;
        }
      }
    }
  }

  &__number {
    color: map_get($colors, 'brand-primary');
    @include font-size($font-size-7);
    font-weight: 300;
  }

  &__text {
    font-weight: bold;
    color: map_get($colors, 'brand-gray');
  }

  &--oversize {
    @include bigleaf-background();
  }
}
