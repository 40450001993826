// =====================================================================================================================
// Variables
// =====================================================================================================================



// =====================================================================================================================
// xl
// =====================================================================================================================

*, *:before, *:after {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

html, body {
  scroll-padding-top: var(--theme_scroll-offset);
}

.container-with-outer-space {
  margin: 0 auto;
  max-width: calc(75rem + (var(--theme_outer-space) * 2));
  padding-left: var(--theme_outer-space);
  padding-right: var(--theme_outer-space);
}

.container {
  @include media-breakpoint-down('xl') {
    max-width: 100%;
  }
}

p, ul, ol {
  @include font-size($font-size-3);

  font-weight: 300;
}

@include headlines() {
  @include break-word();

  font-weight: $font-weight-light;

  b, strong {
    font-weight: 700;
  }

  .page-main & {
    max-width: divide(map_get($container-max-widths, 'xl'), 2);
  }
}

h6, .h6 {
  &:not(.content-heading) {
    font-weight: $font-weight-bold;
    color: $primary;
    letter-spacing: (1em * divide(5, 100));
    text-transform: uppercase;
  }
}

img {
  height: auto;
  max-width: 100%;
}

figcaption {
  @include break-word();
  width: 100%;
  max-width: map_get($container-deviant-sizes, 'sm');
  margin: $font-size-base auto 0;
  font-size: $font-size-2;
  color: map_get($colors, 'brand-gray');
}

embed,
iframe,
object {
  max-width: 100%;
}

abbr {
  white-space: nowrap;
  &[title],
  &[data-original-title] {
    text-decoration: none;
    cursor: inherit;
  }
}

input,
textarea,
label,
select,
button {
  fieldset:disabled &,
  fieldset[disabled] &,
  &:disabled,
  &[disabled] {
    pointer-events: none;
  }
}

@if($enable-guide-lines == true) {
  .container-guide {
    pointer-events: none;
    z-index: 10000;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding-left: var(--theme_outer-space);
    padding-right: var(--theme_outer-space);

    &__md,
    &__lg,
    &__sm {
      border-left: 0.5px solid red;
      border-right: 0.5px solid red;

      height: 100%;
    }
  }
}
@else
{
  .container-guide {
    display: none;
  }
}

@if($enable-headline-highlight == true) {
  @for $i from 1 through 6 {
    h#{$i} {
      border: red 1px dotted !important;

      &::before {
        background: red;
        color: white;
        content: 'H#{$i}';
      }
    }
  }
}

// =====================================================================================================================
// lg
// =====================================================================================================================

@include media-breakpoint-down('lg') {
}

// =====================================================================================================================
// md
// =====================================================================================================================

@include media-breakpoint-down('md') {
}

// =====================================================================================================================
// sm
// =====================================================================================================================

@include media-breakpoint-down('sm') {
}

// =====================================================================================================================
// xs
// =====================================================================================================================

@include media-breakpoint-down('xs') {
}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {
}
