.footer-menu {
  --nav-space-x: #{1em * divide(9, 8)};
  --nav-space-y: 0.5rem;
  --bg-color: #{lighten($primary, 5%)};
  --nav-direction: column;
  --nav-container: column;
  --nav-margin: 0 auto;
  --nav-align: center;
  --copyright-align: center;


  @include media-breakpoint-up(md) {
    --nav-space-y: 0.25em;
    --bg-color: #{$primary};
    --nav-direction: row;
    --nav-container: row;
    --nav-margin: 0 auto 0 0;
    --nav-align: left;
    --copyright-align: left;
  }

  font-size: $font-size-2;
  background-color: var(--bg-color);
  color: $white;
  padding: ($font-size-base * divide(11, 8)) 0;

  a {
    color: inherit;
  }

  &__nav {
    margin: var(--nav-margin);
    text-align: var(--nav-align);

    a {
      text-decoration: none;

      @include media-breakpoint-down(md) {
        display: block;
        padding: var(--nav-space-y) var(--nav-space-x);
      }


      &:hover,
      &:focus {
        text-decoration: underline;
        outline: none;
      }
    }

    ul {
      font-size: inherit;

      display: flex;
      flex-wrap: wrap;
      flex-direction: var(--nav-direction);
      list-style: none;
      margin: calc(var(--nav-space-y) * -1) 0 calc(var(--nav-space-y) * -1) calc(var(--nav-space-x) * -1);
      padding: 0;
    }

    li {
      @include media-breakpoint-up(md) {
        margin: var(--nav-space-y) var(--nav-space-x);
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: var(--nav-container);
    margin-top: divide(-1.125rem, 2);

    & > * {
      margin-top: divide(1.125rem, 2);
    }
  }

  &__copyright {
    text-align: var(--copyright-align);
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }
}
