.text-switch {
  $self: &;

  --text-switch--on-opacity: 0;
  --text-switch--off-opacity: 0;

  --text-switch--selector-left: 0px;
  --text-switch--selector-right: auto;

  &__input {
    display: none;
  }

  &__button {
    @include reset-button;

    background: map_get($colors, 'brand-silver');
    border-radius: 9999rem;

    display: flex;
    flex-direction: row;
    position: relative;

    &:before {
      --text-switch--left-offset: var(--text-switch--selector-width, 0px);

      content: '';
      position: absolute;
      background: white;
      top: 0;
      bottom: 0;

      left: calc(var(--text-switch--left-offset, 0px) - var(--text-switch--selector-width, 0px));

      transition: left 0.25s ease-in-out, width 0.25s ease-in-out;

      will-change: left;

      width: var(--text-switch--selector-width, 0px);
      border-radius: 9999rem;

      box-shadow: 0 0 var(--theme_leaf-shadow-size) rgba(0, 0, 0, 0.1);
    }
  }

  &__off,
  &__on {
    display: block;
    padding: 0.66rem 1.5rem;
    z-index: 1;

    transition: opacity 0.25s ease-in-out;
  }

  &__on {
    opacity: 0.5;
  }

  &__off {
    opacity: 1;
  }

  &--on {
    #{$self}__on {
      opacity: 1;
    }

    #{$self}__off {
      opacity: 0.5;
    }

    #{$self}__button::before {
      --text-switch--left-offset: 100%;
    }

    --text-switch-off-opacity: 0;
    --text-switch-on-opacity: 1;

  }
}
