@mixin fontello() {
  display: inline-block;
  font-family: fontello;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size-adjust: none;
  font-size: inherit;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
