.check-container {
  &__headline {
    @include font-size($h1-font-size);

    font-weight: 300;
    text-align: center;

    margin-bottom: map-get($spacers, 5);
  }

  .leaf {
    min-height: ($font-size-base * 20);
    height: 100%;

    &__icon {
      .fa {
        font-size: ($font-size-base * divide(25, 8));
        color: map_get($colors, 'brand-primary');
        transform-origin: center bottom;
        transition: transform ($beat * 2);
      }
    }

    &:hover {
      .fa-check {
        transform: scale(divide(9, 8));
      }
    }
  }

  &__footer {
    margin-top: 5rem;
    text-align: center;

    .btn {
      margin-top: 1rem;
    }
  }
}
