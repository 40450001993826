@use 'sass:map';

.footer-cta {

  &__cta {
    padding: var(--theme_section-padding) var(--theme_outer-space);
    text-align: center;

    @include headlines() {
      @include font-size($font-size-7);
      font-weight: $font-weight-bold;
    }
  }

  &__menu {
    display: flex;
    justify-content: center;

    padding: calc(var(--theme_section-padding) / 2) var(--theme_outer-space);
  }

  &__menu__list {
    display: flex;
    align-items: center;
    flex-direction: column;

    list-style-type: none;

    gap: calc(var(--theme_section-padding) * 0.5);

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      gap: var(--theme_section-padding);
    }

    .nav-link {
      color: map.get($theme-colors, 'black');
      font-weight: $font-weight-base;

      &:hover {
        color: map.get($theme-colors, 'primary');
      }
    }

  }
}
