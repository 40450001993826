/* ------------------------------------------------------------------------------------------------------------------ */
/* Origin of all that is good and mother to us all…
/* ------------------------------------------------------------------------------------------------------------------ */

$font-size-base: 1rem;
$font-size-increased: $font-size-base * 1.25;

/* ------------------------------------------------------------------------------------------------------------------ */
/* Pre-compile custom variables (do NOT redeclare any bootstrap variables here! only add your own shit) */
/* ------------------------------------------------------------------------------------------------------------------ */

$random: random(999999999);

$beat: 0.25s;

$gray: #e0e0e;
$white: #fff;
$gray-900: #212529;
$black: #000;
$red: #DC3545;

// Typography
$font-arial: 'Arial Pro', Arial, 'Helvetica Neue', Helvetica, sans-serif;

$font-size-1: $font-size-increased * divide(12, 20);
$font-size-2: $font-size-increased * divide(16, 20);
$font-size-3: $font-size-increased * divide(20, 20); // Base content font-size, should compute to 16px
$font-size-4: $font-size-increased * divide(24, 20);
$font-size-5: $font-size-increased * divide(30, 20);
$font-size-6: $font-size-increased * divide(40, 20);
$font-size-7: $font-size-increased * divide(55, 20);
$font-size-8: $font-size-increased * divide(75, 20);
$font-size-9: $font-size-increased * divide(90, 20);
$font-size-10: $font-size-increased * divide(135, 20);

$headline-font-sizes: (
  1: $font-size-6,
  2: $font-size-6,
  3: $font-size-5,
  4: $font-size-4,
  5: $font-size-3,
  6: $font-size-2,
);

$font-weight-semi-bold: 500;
$font-weight-extra-bold: 900;

$form-check-input-width: 1.5em;
$form-check-margin-bottom: 0;

// Layout

$container-deviant-sizes: (
  'sm': 784px,
  'md': 992px,
  'lg': 1040px,
  'xl': 1600px,
);

// Text color Overwrites
$text-color-overwrites: (
  'brand-petrol': (
    'text': $white,
    'links': $white,
  ),
  'brand-silver': (
    'bird-color': $white,
    'bird-opacity': divide(12, 16),
  ),
  'brand-neutral-gray-3': (
    'text': $white,
    'links': $white,
  ),
  'brand-coralle': (
    'text': $white,
    'links': $white,
  ),
  'brand-coralle-highlight': (
    'text': $white,
    'links': $white,
  ),
  'brand-dark': (
    'text': $white,
    'links': $white,
  ),
  'brand-ocean': (
    'text': $white,
    'links': $white,
  ),
);

$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;

/* ------------------------------------------------------------------------------------------------------------------ */
/* Pre-compile bootstrap overrides (do NOT declare any custom variables here) */
/* ------------------------------------------------------------------------------------------------------------------ */

// Options
$enable-smooth-scroll: false;

// Colors

$block-space: 3rem;
$shadow-color: #000;

$colors: (
  'brand-primary': #007487,
  'brand-secondary': #D5494E,
  'brand-silver': #F2EDEC,
  'brand-gray': #959695,
  'brand-copper': #95918C,
  'brand-dark': #222222,
  'brand-ocean': #4CAA86,

  'brand-neutral-gray-1': #F2F2F2,
  'brand-neutral-gray-2': #CFD0D0,
  'brand-neutral-gray-3': #959695,
  'brand-light-gray-2': #CFCBCA,

  'brand-coralle': #D5494E,
  'brand-coralle-highlight': #F07B7F,
  'brand-petrol': #007487,
  'brand-purple': #6E4E89
);

$colors: map_merge($colors, (
  // 'brand-gold-light': lighten(map_get($colors, 'brand-gold'), percentage(3/16)),
));

$primary: map_get($colors, 'brand-primary');
$secondary: map_get($colors, 'brand-secondary');
$danger: $red;

$theme-colors: (
  // Which colors to create bootstrap-components from (buttons, badges, backgrounds, tables, alerts etc.)
  'white': $white,
  'black': $black,
  'primary': $primary,
  'secondary': $secondary,
  'danger': $danger,
  'brand-dark': map-get($colors, 'brand-dark'),
  'brand-silver': map_get($colors, 'brand-silver'),
  'brand-ocean': map_get($colors, 'brand-ocean'),

  'brand-neutral-gray-1': #F2F2F2,
  'brand-neutral-gray-2': #CFD0D0,
  'brand-neutral-gray-3': #959695,
  'brand-light-gray-2': #CFCBCA,

  'brand-coralle': #D5494E,
  'brand-coralle-highlight': #F07B7F,
  'brand-petrol': #007487,
  'brand-purple': #6E4E89
);

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1367px
);

// Grid containers

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1320px
);

// Grid columns

$grid-columns: 24;
$grid-row-columns: 4;

// Spacers

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  's': var(--theme_section-padding),
  's2': calc(var(--theme_section-padding) * 2),
  's3': calc(var(--theme_section-padding) * 3),
  's4': calc(var(--theme_section-padding) * 4),
);

// Body

$body-color: $gray-900 !default;

// Typography

$font-family-base: $font-arial;

$font-size-lg: $font-size-4;
$font-size-sm: $font-size-2;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;

$h1-font-size: map-get($headline-font-sizes, 1);
$h2-font-size: map-get($headline-font-sizes, 2);
$h3-font-size: map-get($headline-font-sizes, 3);
$h4-font-size: map-get($headline-font-sizes, 4);
$h5-font-size: map-get($headline-font-sizes, 5);
$h6-font-size: map-get($headline-font-sizes, 6);

$headings-margin-bottom: 1rem;
$headings-font-weight: $font-weight-normal;

// Buttons
$override-button-text-color: (
  'brand-coralle': $white,
  'brand-coralle-highlight': $white,
);

$btn-padding-y: (1em * divide(7, 16));
$btn-padding-x: 1.5em;

$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: $btn-padding-x;
$btn-font-size-sm: $font-size-2;

$btn-padding-y-lg: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;
//$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-font-weight: $font-weight-bold;

$btn-border-radius: 1.25em;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

// Pagination

$pagination-padding-x: ($font-size-base * divide(9, 8));

$pagination-color: $body-color;
$pagination-bg: transparent;
$pagination-border-width: 0;

$pagination-focus-color: $primary;
$pagination-focus-bg: rgba($black, divide(1, 16));

$pagination-hover-color: $pagination-focus-color;
$pagination-hover-bg: $pagination-focus-bg;

$pagination-active-color: $pagination-hover-color;
$pagination-active-bg: $pagination-bg;

$pagination-disabled-color: $pagination-color;
$pagination-disabled-bg: $pagination-bg;


/* ------------------------------------------------------------------------------------------------------------------ */
/* Import bootstrap base stuff */
/* ------------------------------------------------------------------------------------------------------------------ */

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

/* ------------------------------------------------------------------------------------------------------------------ */
/* Post-compile bootstrap overrides (further tweaking of compiled bootstrap variables) */
/* ------------------------------------------------------------------------------------------------------------------ */



/* ------------------------------------------------------------------------------------------------------------------ */
/* Post-compile custom variables (do NOT redeclare any bootstrap variables here! only add your own shit) */
/* ------------------------------------------------------------------------------------------------------------------ */
