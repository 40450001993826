.icon-block {
  $self: &;

  --icon-width: 2.25em;

  display: inline-flex;
  margin-bottom: 0.25em;
  align-items: baseline;

  & &__text {
    @include break-word();

    margin-left: 0.75em;
    line-height: 1.5;
  }

  & &__icon {
    width: var(--icon-width);
    display: flex;
    align-items: center;
    line-height: 1.5;
    flex-shrink: 0;

    font-size: 1.15em;
  }

  @include icon-normalize();
  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  &:not(&--move-arrow) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &--tight {
    --icon-width: 1.75rem;

    #{$self}__text {
      margin-left: 0;
    }
  }

  &--icon-on-mobile {
    --icon-width: 2.25rem;
    position: relative;

    #{$self}__icon {
      justify-content: center;
    }

    #{$self}__text {
      font-size: 0;

      a:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &--move-arrow a,
  &--move-arrow a:hover,
  &--move-arrow a:focus {
    text-decoration: none;
  }

  &--move-arrow a &__arrow {
    transform: translateX(0rem);
    transition: transform 0.25s ease-in-out;

    margin-left: 0.25rem;
  }

  &--move-arrow a:hover &__arrow,
  &--move-arrow a:focus &__arrow {
    transform: translateX(0.33rem);
  }
}

@include media-breakpoint-up(md) {
  .icon-block {
    $self: &;

    &--icon-on-mobile {
      position: relative;

      #{$self}__icon {
        justify-content: flex-start;
      }

      #{$self}__text {
        font-size: inherit;

        a:after {
          display: none;
        }
      }
    }
  }
}


