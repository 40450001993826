// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.content-heading {
  $self: &;

  --headline-color: #{map-get($theme-colors, 'primary')};
  --subheadline-color: #000000;
  --icon-color: var(--headline-color);

  @each $background, $overwrites in $text-color-overwrites {
    @at-root .bg-#{$background} & {
      --headline-color: #{map-get($overwrites, 'text')};
      --subheadline-color: #{map-get($overwrites, 'text')};
    }
  }

  color: var(--headline-color);

  &__icon {
    color: var(--icon-color);
    font-size: $font-size-8;

    margin-bottom: map-get($spacers, 4);
  }

  &__subheadline {
    @include font-size($font-size-2);

    text-transform: uppercase;
    letter-spacing: 1px;

    color: var(--subheadline-color);

    margin-bottom: map-get($spacers, 2);
  }

  & &__headline {
    @include font-size($font-size-6);
    color: var(--headline-color);
  }

  &__title {
    font-weight: $font-weight-bold;
    text-transform: none !important; // Revert changes in leaf
  }

  &__description {
    text-transform: none !important; // Revert changes in leaf
  }

  &--invert-colors {
    --headline-color: #000000;
    --subheadline-color: #{map-get($theme-colors, 'primary')};

    @each $background, $overwrites in $text-color-overwrites {
      @at-root .bg-#{$background} & {
        --subheadline-color: $white;
      }
    }

    #{$self}__subheadline {
      font-weight: $font-weight-bold;
    }
  }

  &--sm {
    #{$self}__headline {
      @include font-size($font-size-5);
    }
  }
}



// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .content-heading {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .content-heading {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .content-heading {
    $self: &;

    &__icon {
      margin-bottom: map-get($spacers, 3);
    }

    // Sector heading will be smaller without description
    &--reduce-on-mobile {
      text-align: center;

      #{$self} {
        &__icon {
          margin-bottom: map-get($spacers, 2);
        }

        &__subheadline,
        &__headline > *:not(&__title) {
          display: none;
        }
      }
    }

    &--decolor-on-mobile {
      --headline-color: black;
      --subheadline-color: var(--headline-color);
      --icon-color: #{map-get($colors, 'brand-copper')};

      @each $background, $overwrites in $text-color-overwrites {
        @at-root .bg-#{$background} & {
          --headline-color: #{map-get($overwrites, 'text')};
          --icon-color: #{map-get($overwrites, 'text')};
        }
      }
    }

    &--hide-icon-on-mobile {
      .content-heading__icon {
        display: none;
      }
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .content-heading {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .content-heading {
  }

}
