.img-with-blockquote-container {
  padding-bottom: map-get($spacers, 5);

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }

  &__col-1 {
    & > :first-child {
        position: relative;
        display: flex;
        flex-direction: column-reverse;

        @include media-breakpoint-up(md) {
          flex-direction: row;

          & > * {
            width: 50%;
          }
        }

        &::before {
          @include media-breakpoint-up(md) {
            @include fontello();
            color: #CFD0D0;
            line-height: normal;
            position: absolute;
            font-size: px2rem(230);
            bottom: 1rem;
            left: calc(50% - calc(#{px2rem(230)} / 2) + 1.5rem);
            content: '\e90b';

            z-index: 1;
          }

          @include media-breakpoint-up(lg) {
            font-size: px2rem(250);
            bottom: calc(#{px2rem(250)} / 2 * -1);
            left: calc(50% - calc(#{px2rem(250)} / 2) + 1.5rem);
          }
        }

        blockquote {
          text-align: left;
          margin-top: 1rem;

          @include media-breakpoint-up(md) {
            text-align: right;
            padding-bottom: calc(#{px2rem(305)} / 2);
            margin-top: 0;
          }
        }

        figure {
          height: 100%;
        }

        img {
          border-radius: var(--theme_img-border-radius);

          @include media-breakpoint-up(md) {
            object-fit: cover;
          }
        }

        .img-with-blockquote-container__figure {
          position: relative;
          margin-bottom: 0;

          @include media-breakpoint-down(md) {

            &::before {
              @include fontello();
              color: #CFD0D0;
              line-height: normal;
              position: absolute;
              font-size: px2rem(130);
              bottom: 2.5rem;
              left: calc(8.125rem / 2 + 5.5rem * -1);
              content: '\e90b';
            }
          }
        }
    }

    @include media-breakpoint-down(md) {
      order: 2;
    }
  }

  &__col-2 {
    @include media-breakpoint-down(md) {
      order: 1;
    }
  }
}
