.jumbo {
  --max-width: 1920px;
  --aspect-ratio: #{divide(2, 1)};
  --padding-y: calc((var(--theme_section-padding) * 2) + var(--theme_spacer-size));
  --inner-padding-top: calc(var(--theme_spacer-size) * 6);
  --inner-padding-bottom: calc(var(--theme_spacer-size) * 6);

  $jumbo-font-size: $font-size-5;
  $jumbo-font-size--sm: $font-size-3;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: var(--max-width);
  min-height: calc(var(--max-width) / var(--aspect-ratio));
  margin-right: auto;
  margin-left: auto;
  padding: var(--padding-y) var(--theme_outer-space);
  background-color: rgba($black, divide(1, 8));
  color: $white;
  position: relative;
  z-index: $z-index-1;

  @include media-breakpoint-down(lg) {
    --aspect-ratio: #{divide(4, 1)};

    --inner-padding-top: calc(var(--theme_spacer-size) * 3);
    --inner-padding-bottom: calc(var(--theme_spacer-size) * 3);
  }

  &__darken,
  &__v-height {
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    pointer-events: none;
  }

  &__darken {
    height: 100%;
    background-color: $black;
    opacity: 0;
  }

  &__v-height {
    width: 1px;
    height: 100%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%);
  }

  &__inner {
    @include break-word();
    width: 100%;
    max-width: map_get($container-deviant-sizes, 'md');
    margin: 0 auto;
    padding-top: var(--inner-padding-top);
    padding-bottom: var(--inner-padding-bottom);
    text-align: center;
    position: relative;
  }


  &__media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    margin-bottom: ($font-size-base * 2.25);
    line-height: 1;
    font-size: ($font-size-8 - 1rem);

    @include media-breakpoint-up(md) {
      font-size: $font-size-8;
    }
  }

  &__slot {
    @extend .fw-bold;
    @include font-size($jumbo-font-size);

    line-height: $headings-line-height;

    @include headlines() {
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;

      ~ p {
        @extend .mt-3;
        @include font-size($jumbo-font-size--sm);

        font-weight: $font-weight-normal;
        line-height: 1.25;
      }
    }

    p {
      @extend .mt-3;

      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
    }

    &__description {
      @extend .mt-3;
      @include font-size($jumbo-font-size--sm);

      font-weight: $font-weight-bold;
      line-height: 1.25;
    }
  }

  &--gradient {
    &-top:before,
    &-bottom:after {
      content: '';
      width: 100%;
      height: 25%;
      position: absolute;
      z-index: $z-index-3;
      left: 0;
      background-image: linear-gradient(to bottom,
      rgba($white, divide(4, 4)) percentage(divide(0, 3)),
      rgba($white, divide(3, 4)) percentage(divide(1, 3)),
      rgba($white, divide(0, 4)) percentage(divide(3, 3)));
      pointer-events: none;
    }

    &-top:before {
      top: -1px;
    }

    &-bottom:after {
      bottom: -1px;
      transform: scaleY(-1);
    }
  }

  &--sm {
    --padding-y: calc((var(--theme_section-padding) * 1.75) + var(--theme_spacer-size));
    --inner-padding-top: calc(var(--theme_spacer-size) * 4);
    --inner-padding-bottom: calc(var(--theme_spacer-size) * 4);

    min-height: 0;
  }

  &--weigh-up {
    --inner-padding-top: 0;
  }

  &--weigh-down {
    --inner-padding-bottom: 0;
  }
}

// Add space for jumbo without wrapping content section
.content-section:not(.content-section--push-down) + .jumbo:not(.jumbo--sm) {
  margin-top: var(--theme_section-padding);
}

.jumbo:not(.jumbo--sm) + .content-section:not(.content-section--push-up) {
  margin-top: var(--theme_section-padding);
}

// Remove outer spacing, when jumbo is inside content-section
.content-section .jumbo--sm {
  margin-top: calc(var(--theme_section-padding) * -1);
  margin-bottom: calc(var(--theme_section-padding) * -1);
}
