@mixin inherit-text {
  font-family: inherit !important;
  font-size: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  text-align: inherit !important;
  text-decoration: inherit !important;
  text-shadow: inherit !important;
  text-transform: inherit !important;
  letter-spacing: inherit !important;
  word-break: inherit !important;
  word-spacing: inherit !important;
  white-space: inherit !important;
  line-break: inherit !important;
}
