.footer-contact {
  position: relative;
  --text-color: #{$white};
  --content-heading-color: #{$white};

  --leaf-max-width: 100%;
  --leaf-padding: 0 1rem;
  --leaf-margin: #{$font-size-base * 2} 0;
  --leaf-bg-color: tranparent;
  --leaf-button-space-top: var(--theme_section-padding);
  --leaf-button-bg: #{$secondary};

  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position-y: 25%;
  background-color: $primary;

  margin: var(--theme_section-padding) auto 0;

  @include media-breakpoint-up(md) {
    --text-color: #{$body-color};
    --content-heading-color: #{$primary};

    --leaf-max-width: #{px2rem(480)};
    --leaf-padding: 2.813rem;
    --leaf-margin: #{$font-size-base * 4} 0;
    --leaf-bg-color: #{rgba(243, 243, 243, 0.64)};
    --leaf-button-space-top: calc(var(--theme_section-padding) / 2);
    --leaf-button-bg: #{$primary};

    min-height: 760px;
    padding-bottom: ($font-size-base * 4);
  }

  @include media-breakpoint-up(xl) {
    --leaf-max-width: #{px2rem(680)};
  }

  &__bg {
    margin: 0 calc(var(--theme_outer-space) * -1);

    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      @include media-breakpoint-up(md) {
        @supports (object-fit: cover) {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .leaf {
    width: 100%;
    max-width: var(--leaf-max-width);
    margin: var(--leaf-margin);
    background-color: var(--leaf-bg-color);

    @supports (backdrop-filter: blur(8px)) {
      backdrop-filter: blur(8px);
    }

    &__body {
      text-align: center;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    &__main {
      padding: var(--leaf-padding);
    }

    .btn {
      margin-top: var(--leaf-button-space-top);
      border-color: var(--leaf-button-bg);
      background-color: var(--leaf-button-bg);

      &::after {
        content: none;
      }
    }
  }

  &__leaf-inner {
    width: 100%;
    max-width: 32.5rem;
    margin: 0 auto;
    padding: ($font-size-base * 2) 0;

    &, & p {
      color: var(--text-color);
    }
  }

  .content-heading {
    &__title {
      @include font-size($font-size-5);
      color: var(--content-heading-color);
      font-weight: $font-weight-bold;
    }

    &__subheadline {
      @include media-breakpoint-down(md) {
        color: var(--content-heading-color);
      }
    }
  }
}
