// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

.spacer {
  &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8 {
    font-size: var(--theme_spacer-size);
    line-height: 0;
    height: 1em * 1.5;
    pointer-events: none;
  }
  &-2 { height: 1em * 2; }
  &-3 { height: 1em * 3; }
  &-4 { height: 1em * 4; }
  &-5 { height: 1em * 5; }
  &-6 { height: 1em * 6; }
  &-7 { height: 1em * 7; }
  &-8 { height: 1em * 8; }
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .spacer {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .spacer {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .spacer {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .spacer {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .spacer {
  }

}
