.staff-grid {
  --gutter: #{$font-size-base * 2};
  --items-per-row: 1;
  --col-width: calc(100% / var(--items-per-row));

  @include media-breakpoint-up('sm') {
    --items-per-row: 2;
  }

  @include media-breakpoint-up('md') {
    --items-per-row: 3;
    --gutter: #{$font-size-base * 3};
  }

  @include media-breakpoint-up('lg') {
    --items-per-row: 4;
  }

  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) / -2);
  margin-left: calc(var(--gutter) / -2);

  &__items-per-row {
    display: none;
    order: var(--items-per-row);
  }

  &__col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: var(--col-width);
    max-width: 100%;
    margin-top: var(--gutter);
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);
  }

  &__details {
    width: 100%;
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);
    margin-top: ($font-size-base * 2);

    &-inner {
      width: 100%;
      max-width: 576px;
      margin: 0 auto;

      &.l {
        margin-left: 0;
      }

      &.r {
        margin-right: 0;
      }
    }
  }
}

