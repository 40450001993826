.jumbo-new {
  --max-width: 1920px;
  --aspect-ratio: #{divide(3, 1)};
  --padding-y: var(--theme_section-padding);
  --padding-top: calc(var(--padding-y) * 2.5);

  $jumbo-font-size: $font-size-6;
  $jumbo-font-size--sm: $font-size-3;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: var(--max-width);
  min-height: calc(var(--max-width) / var(--aspect-ratio));
  margin-right: auto;
  margin-left: auto;
  padding: var(--padding-y) var(--theme_outer-space);
  padding-top: var(--padding-top);
  background-color: rgba($black, divide(1, 8));
  color: $white;
  position: relative;
  z-index: $z-index-1;

  @include media-breakpoint-down(lg) {
    --aspect-ratio: #{divide(4, 1)};
  }

  &__darken,
  &__v-height {
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    pointer-events: none;
  }

  &__darken {
    height: 100%;
    background-color: $black;
    opacity: 0;
  }

  &__v-height {
    width: 1px;
    height: 100%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%);
  }

  &__flag {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: #{$z-index-3 + 1};

    @include font-size($font-size-5);
    font-weight: $font-weight-bold;

    width: 100%;
    max-width: min(calc(100vw - var(--theme_outer-space) * 2 - 1rem), #{map_get($container-max-widths, 'xl')});
    margin: 0 auto;

    &__content {
      --flag-background-color: #{map-get($theme-colors, 'primary')};
      --flag-padding-x: 2.25em;
      --flag-padding-y: 1em;

      display: inline-block;
      position: relative;
      background-color: map-get($theme-colors, 'primary');

      > *:last-child {
        margin-bottom: 0;
      }

      @include headlines {
        @include font-size($font-size-8);
        font-weight: inherit;
      }

      @each $name, $color in $theme-colors {
        &.bg-#{$name} {
          --flag-background-color: #{$color};
        }
      }

      &:after {
        display: block;
        content: '';
        position: absolute;

        top: calc(var(--flag-padding-y) * -1);
        bottom: calc(var(--flag-padding-y) * -1);
        right: calc(var(--flag-padding-x) * -1);
        left: calc(var(--flag-padding-x) * -1);

        border-radius: var(--theme_img-border-radius);
        background-color: var(--flag-background-color);

        z-index: -1;
      }

      @include media-breakpoint-down(lg) {
        --flag-padding-x: 1em;
        margin-left: var(--flag-padding-x);
      }
    }
  }

  &__inner {
    @include break-word();
    width: 100%;
    max-width: map_get($container-max-widths, 'xl');
    margin: 0 auto;
    padding-top: var(--inner-padding-top);
    padding-bottom: var(--inner-padding-bottom);
    position: relative;
  }


  &__media {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slot {
    @extend .fw-bold;
    @include font-size($jumbo-font-size);

    line-height: $headings-line-height;

    @include headlines() {
      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;

      ~ p {
        @extend .mt-3;
        @include font-size($jumbo-font-size--sm);

        font-weight: $font-weight-normal;
        line-height: 1.25;
      }
    }

    p {
      @extend .mt-3;

      font-weight: inherit;
      font-size: inherit;
      font-family: inherit;
    }

    &__description {
      @extend .mt-3;
      @include font-size($jumbo-font-size--sm);

      font-weight: $font-weight-bold;
      line-height: 1.33;
    }
  }

  &--gradient {
    &-top:before,
    &-bottom:after {
      content: '';
      width: 100%;
      height: 25%;
      position: absolute;
      z-index: $z-index-3;
      left: 0;
      background-image: linear-gradient(to bottom,
      rgba($white, divide(4, 4)) percentage(divide(0, 3)),
      rgba($white, divide(3, 4)) percentage(divide(1, 3)),
      rgba($white, divide(0, 4)) percentage(divide(3, 3)));
      pointer-events: none;
    }

    &-top:before {
      top: -1px;
    }

    &-bottom:after {
      bottom: -1px;
      transform: scaleY(-1);
    }
  }

  &--sm {
    --padding-y: calc((var(--theme_section-padding) * 1.75) + var(--theme_spacer-size));
    --inner-padding-top: calc(var(--theme_spacer-size) * 4);
    --inner-padding-bottom: calc(var(--theme_spacer-size) * 4);

    min-height: 0;
  }

  &--weigh-up {
    --inner-padding-top: 0;
  }

  &--weigh-down {
    --inner-padding-bottom: 0;
  }
}

// Add space for jumbo without wrapping content section
//.content-section:not(.content-section--push-down) + .jumbo-new:not(.jumbo-new--sm) {
//  margin-top: var(--theme_section-padding);
//}
//
//.jumbo-new:not(.jumbo-new--sm) + .content-section:not(.content-section--push-up) {
//  margin-top: var(--theme_section-padding);
//}
//
//// Remove outer spacing, when jumbo is inside content-section
//.content-section .jumbo-new--sm {
//  margin-top: calc(var(--theme_section-padding) * -1);
//  margin-bottom: calc(var(--theme_section-padding) * -1);
//}
