.blockquote-content {
  &__inner {
    @include break-word();
    @include font-size(1.563rem);
    font-weight: $font-weight-bold;
    line-height: divide(19, 16);
    color: $primary;

    &:before {
      @include fontello();
      display: block;
      content: '\e912';
      line-height: divide(7, 4);
      font-size: ($font-size-base * 1.5);
    }

    footer {
      @include font-size(1rem);
      font-weight: $font-weight-normal;
      line-height: $line-height-base;
      color: map_get($colors, 'brand-gray');
      margin-top: ($font-size-base * divide(7, 8));
    }
  }

  &--centered {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      max-width: 50%;
      margin: 6rem auto;

      .blockquote-content__inner {
        &::before {
          transform: translate(-3rem, 1.25rem);
        }
      }
    }
  }
}