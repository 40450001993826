.leaf {
  --padding-y: 2.5rem;
  --padding-x: 1.5rem;
  --footer-space: 1.875rem;
  --sock-image-size: #{px2rem(70)};

  @include break-word-hyphens();
  display: flex;
  flex-direction: column;
  background-color: $white;
  color: $black;
  border-radius: var(--theme_leaf-border-radius);
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 var(--theme_leaf-shadow-size) rgba($black, divide(1, 10));
  position: relative;
  z-index: $z-index-2;
  @include font-size($font-size-3);

  &:not(:only-child):not(:last-child) {
    margin-bottom: #{map-get($spacers, 5)};
  }

  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-y) var(--padding-x);
  }

  &__img {
    border-top-right-radius: var(--theme_leaf-border-radius);
    background-color: rgba($black, divide(1, 8));

    a,
    img {
      border-radius: inherit;
    }

    img {
      width: 100%;
      height: px2rem(190);
      object-fit: cover;
    }
  }

  &__icon {
    font-size: 2.75rem;
    color: map_get($colors, 'brand-copper');

    & + * {
      margin-top: 1.875rem;
    }
  }

  &__contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--footer-space);

    @include font-size($font-size-2);

    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  & &__body {
    flex-grow: 1;
    text-align: center;
    line-height: 1.4;
    font-weight: 300;

    @include headlines() {
      color: #000000;
    }
  }

  &__footer {
    margin-top: var(--footer-space);

    &:empty {
      display: none;
    }
  }

  &__sock {
    position: relative;

    a {
      color: inherit;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    &-image {
      height: calc(var(--sock-image-size) / 2);

      > div {
        position: absolute;
        left: ($font-size-base * 1.25);
        font-size: var(--sock-image-size);
        width: 1.25em;
        height: 1.25em;
        border-radius: 50%;
      }

      ~ * {
        text-align: right;
      }
    }

    &-body {
      font-size: $font-size-2;
      color: $white;
      padding: ($font-size-base * 1.25) var(--padding-x);
      background-color: $primary !important;
      border-radius: var(--theme_leaf-border-radius);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--career {
    --padding-y: #{$font-size-base * divide(9, 8)};
    --padding-x: #{$font-size-base * 3};
  }

  &--career,
  &--content {
    color: $white;
    background-color: $primary;
    flex-direction: row;
    height: auto;
    font-size: $font-size-2;
    line-height: divide(5, 4);
    min-height: px2rem(122 * 0.8);

    a {
      color: inherit;
      text-decoration: underline;
      white-space: nowrap;
    }

    a.btn {
      text-decoration: none;
    }

    .image-leaf {
      padding-top: 0 !important;
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: ($font-size-base * 10);

      @include media-breakpoint-up('sm') {
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: cover;
        }
      }
    }

    .leaf {
      &__main {
        align-items: flex-start;
        justify-content: center;
      }

      &__body {
        flex-grow: 0;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        p,
        a {
          color: inherit;
        }

        a {
          &:hover,
          &:active,
          &:focus {
            color: darken($white, 15%);
          }
        }

        a.btn {
           &:hover,
           &:active,
           &:focus {
             color: #212529;
           }
         }

        @include headlines {
          @include font-size($font-size-3);
          color: inherit;

          @include media-breakpoint-up(md) {
            @include font-size($font-size-4);
          }
        }

        ul {
          @extend .circle-list;
          @extend .circle-list--indented;

          text-align: left;

          li::before {
            color: inherit;
          }
        }
      }
    }
  }

  &--content,
  &--career {
    .image-leaf {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .leaf {
      &__body {
        max-width: none;
      }
    }


  }

  &--sidebar {
    --padding-y: #{$font-size-base * 1.75};
    --padding-x: #{$font-size-base * 1.5};

    margin: 0 !important;

    .leaf {
      &__main {
        align-items: normal;
      }

      &__body {
        text-align: left;

        h2,
        .h2 {
          @include font-size($font-size-base * divide(9, 8));
        }

        ol {
          font-size: $font-size-2;
        }
      }

      &__sock {
        &-body {

          h3,
          .h3 {
            font-size: $font-size-base;
          }

        }
      }
    }

  }

  h2,
  .h2,
  h3,
  .h3 {
    @include font-size($font-size-4);
    font-weight: $font-weight-bold;
    margin-bottom: 1.25rem;
    text-decoration: none;

    a {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
      text-decoration: inherit;
    }

    span {
      display: block;
      color: $primary;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      text-transform: uppercase;
    }
  }

  p {
    color: map_get($colors, 'brand-gray');

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:not(&--content):not(&--career):not(&--no-full-clickable) {
    .btn {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
  }

  @include media-breakpoint-down('sm') {
    &--stacked-on-mobile {
      --padding-y: #{$font-size-base * divide(9, 8)};
      --padding-x: #{$font-size-base * 2};

      flex-direction: column;
    }

    &--stacked-on-mobile .image-leaf,
    &--stacked-on-mobile &__img {
      height: 10rem;
    }

    &--hide-image-on-mobile .image-leaf,
    &--hide-image-on-mobile &__img {
      display: none;
    }
  }
}
