.staff-card {
  --opacity: 1;
  --body-color: #{map_get($colors, 'brand-copper')};
  --name-color: #{$body-color};
  --button-color: #{map_get($colors, 'brand-copper')};
  --button-color-hover: #{$black};
  --button-icon-content: '\e91b'; // circle-plus
  --transition-time: #{$beat};
  height: 100%;
  margin-bottom: 0;

  .leaf {
    --footer-space: #{$font-size-base * divide(5, 8)};
    background-color: transparent;
    height: 100%;

    &__main {
      color: var(--body-color);
      padding: $font-size-base 0 0 0;
      transition: color var(--transition-time);
      align-items: normal;
    }
  }

  .image-leaf {
    --image-width: 100%;

    position: relative;
    cursor: pointer;
    width: var(--image-width);
    padding-top: var(--image-width);

    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(md) {
      --image-width: #{100% * divide(2, 3)};
    }
  }

  .image-leaf,
  .leaf__body {
    opacity: var(--opacity);
    transition: opacity var(--transition-time);

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  &__name {
    color: var(--name-color);
    font-weight: $font-weight-normal;
    font-size: $font-size-4;
    margin-bottom: $font-size-base;
    transition: color var(--transition-time);
  }

  &__text {
    font-size: $font-size-2;
  }

  &__details {
    display: none;
  }

  &__btn {
    color: var(--button-color);

    // IOS 1px left fix
    font-family: Arial;

    .fa {
      font-size: px2rem(35 * 0.8);

      // IOS fix
      line-height: var(--bs-body-line-height);

      &:before {
        content: var(--button-icon-content);
      }
    }

    &:hover,
    &:focus {
      color: var(--button-color-hover);
      outline: none;
    }
  }

  &:hover {
    --name-color: #{$primary};
  }

  &.inactive {
    --opacity: #{divide(1, 3)};
    &:hover {
      --opacity: #{divide(2, 3)};
    }
  }

  &.active {
    --body-color: #{$primary};
    --name-color: #{$primary};
    --button-color: #{$primary};
    --button-color-hover: #{shade-color($primary, $btn-hover-bg-shade-amount)};
    --button-icon-content: '\e835'; // circle-minus-filled
  }

  &--no-details {
    pointer-events: none;

    &:hover {
      --name-color: #{$body-color};
    }
  }
}
