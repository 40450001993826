:root {
  --map-push-size: calc(var(--theme_section-padding) * 3 * 0.5);
}

.location-map {
  --map-push-top: 0rem;
  --map-push-bottom: 0rem;
  --map-height: 40rem;
  --map-min-height: calc(var(--map-height) + var(--map-push-top) + var(--map-push-bottom));

  width: 100%;
  max-width: 1920px;
  background-color: #F5F5F5;
  position: relative;
  min-height: var(--map-min-height);

  margin: var(--map-push-top) auto var(--map-push-bottom);

  &__outer {
    padding-top: percentage(divide(3, 11));
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__info-window-title {
    max-width: 14rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      @include font-size($font-size-3);
    }
  }

  &__info-window-plain-link a {
    color: $gray-900;
  }

  &__info-window-content {
    @include font-size($font-size-2);

    font-weight: $font-weight-base;
    line-height: $line-height-base;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding: 1rem;
    }

    a {
      display: block;
      margin-top: 1rem;
    }
  }

  > #{&}__info-window-content {
    display: none !important;
  }

  & + .content-section--push-up {
    --push-size: calc(var(--map-push-size) * -1);
  }

  &.push-overlap--top {
    --map-push-top: var(--map-push-size);
  }

  &.push-overlap--bottom {
    --map-push-bottom: var(--map-push-size);
  }
}

.content-section--push-down {
  & + .location-map {
    --map-push-top: var(--map-push-size);
  }
}


