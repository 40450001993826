// =====================================================================================================================
// Variables
// =====================================================================================================================

$enable-guide-lines: false;
$enable-headline-highlight: false;

// =====================================================================================================================
// xl
// =====================================================================================================================

:root {
  --theme_outer-space: 1.5rem;
  --theme_spacer-size: #{$font-size-base};
  --theme_section-padding: calc(var(--theme_spacer-size) * 8);
  --theme_overflow-size: #{px2rem(120)};

  // Keep header-height in sync with: js/theme/_pageHeader.js
  --theme_header-primary-height: #{px2rem(90)};
  --theme_header-primary-height--collapsed: #{px2rem(48)};
  //--theme_header-secondary-height: #{px2rem(32)};
  --theme_header-secondary-height: 0px;
  --theme_header-height: calc(var(--theme_header-primary-height) + var(--theme_header-secondary-height));

  --theme_scroll-offset: calc(var(--theme_header-height) + #{$font-size-base * 2});

  --theme_leaf-shadow-size: #{(px2rem(50) * 0.8)};
  --theme_leaf-border-radius: #{$font-size-base * 1.5};
  --theme_img-border-radius: 0 #{var(--theme_leaf-border-radius)};
  --theme_slider-border-radius: #{$font-size-base * 1.5};

  --theme_swiper-arrow-size: #{$font-size-base * 2};
  --theme_swiper-arrow-width: 2em;
  --theme-btn-hover-color: #006373;

  --theme_big-modal_close-button-height: 3rem;
  --theme_big-modal_padding-x: #{map-get($spacers, 4) * 1.667};
  --theme_big-modal_padding-y: #{map-get($spacers, 4)};
  --theme_big-modal_top-bar-height: calc(var(--theme_big-modal_close-button-height) + (var(--theme_big-modal_padding-y) * 2));

  @include media-breakpoint-up(md) {
    --theme_outer-space: #{px2rem(48)};
  }
}

// =====================================================================================================================
// lg
// =====================================================================================================================

@include media-breakpoint-down('lg') {

  :root {
    --theme_header-primary-height: #{px2rem(64)};
    --theme_header-primary-height--collapsed: var(--theme_header-primary-height);
    --theme_section-padding: calc(var(--theme_spacer-size) * 3.5);
  }

}

// =====================================================================================================================
// md
// =====================================================================================================================

@include media-breakpoint-down('md') {

  :root {
  }

}

// =====================================================================================================================
// sm
// =====================================================================================================================

@include media-breakpoint-down('sm') {

  :root {
  }

}

// =====================================================================================================================
// xs
// =====================================================================================================================

@include media-breakpoint-down('xs') {

  :root {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  :root {
  }

}
