.btn {
  @each $name, $color in $theme-colors {
    $overwritten-text-color: overwrite-button-text-color($name, color-contrast($color));

    &.btn-#{$name} {
      color: $overwritten-text-color;

      &:hover,
      &:focus,
      &:active {
        color: $overwritten-text-color;
      }
    }

    .btn-outline-#{$name} {
      &:hover,
      &:focus,
      &:active {
        color: $overwritten-text-color;
      }
    }
  }
}
