.gm-style {
  // Safari workaround to avoid overflow because of implicit overflow: scroll padding
  .gm-style-iw-d {
    overflow: visible !important;
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .gm-style-iw {
    border-radius: var(--theme_leaf-border-radius);

    > button {
      top: 0.25rem !important;
      right: 0.25rem !important;

      @include media-breakpoint-up(md) {
        top: 0.5rem !important;
        right: 0.5rem !important;
      }
    }
  }


}
