.page-header {
  --nav-space: #{1em * divide(9, 8)};

  display: flex;
  flex-direction: column-reverse;
  left: 0;
  width: 100%;
  z-index: $zindex-fixed;
  box-shadow: none;
  transition: background-color $beat linear, box-shadow $beat linear, transform $beat linear;

  &__cols {
    display: flex;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .page-header__primary-nav {
    background: $white;
    z-index: 1;
  }

  &__logo {
    img {
      display: block;
      width: 100vw;
      max-width: 9rem;
      max-height: var(--theme_header-primary-height);
      border: ($font-size-base * (divide(5, 8))) solid transparent;
      border-right: none;
      border-left: none;
      transition: max-width .5s;

      @include media-breakpoint-up(md) {
        max-width: px2rem(192);
      }
    }
  }

  ul {
    font-size: $font-size-2;
    font-weight: $font-weight-normal;

    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  button {
    padding: 0 var(--nav-space);
  }

  .nav-link {
    @include media-breakpoint-up(lg) {
      padding: 0 var(--nav-space);
    }
  }

  a,
  button {
    color: inherit;
    text-decoration: none;
    line-height: divide(9, 8);
    transition: color $beat;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $primary;
      outline: none;
    }

    &.active {
      color: map_get($colors, 'brand-primary');
    }

    &.attention > span {
      position: relative;

      &:after {
        content: '';
        display: block;

        position: absolute;
        top: 0;
        right: -0.5rem;

        background: $red;
        border-radius: 50%;

        width: 1rem * divide(3, 8);
        height: 1rem * divide(3, 8);
      }
    }
  }

  button {
    @include reset-button();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 var(--nav-space);

    > span {
      display: flex;
      height: 100%;
      align-items: center;
      position: relative;
    }
  }

  .header__contact {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: auto;
    margin-right: calc(var(--nav-space) / 2);

    font-size: $font-size-3;

    @include media-breakpoint-up(lg) {
      margin-right: 0;
    }

    a {
      padding: var(--nav-space) calc(var(--nav-space) / 2);
    }

    .page-header__btn-search {
      padding: 0 calc(var(--nav-space) / 2);
    }

    .fa {
      vertical-align: middle;
    }
  }

  .navbar {
    font-size: $font-size-base;
    height: var(--theme_header-primary-height);

    &-collapse {
      background-color: $white;
      box-shadow: $box-shadow-sm;

      margin-left: calc(var(--theme_outer-space) * -1);
      margin-right: calc(var(--theme_outer-space) * -1);
      padding-right: var(--theme_outer-space);
      padding-left: var(--theme_outer-space);
      overflow-y: auto;
      max-height: calc(100vh - var(--theme_header-height) + 0.5rem);

      &.collapsing {
        overflow-y: hidden;
      }

      @include media-breakpoint-down(md) {
        margin-top: 0.5rem;

        > ul {
          min-height: calc(100vh - var(--theme_header-height) - var(--theme_outer-space) + 0.5rem);
        }
      }

      @include media-breakpoint-down(lg) {
        margin-top: 0.25rem;

        > ul {
          min-height: calc(100vh - var(--theme_header-height) - var(--theme_outer-space) + 0.5rem);
        }

        > *:last-child {
          margin-bottom: var(--theme_outer-space) !important;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        height: auto;
        overflow: inherit;

        box-shadow: none;
      }
    }

    .header-secondary__ul {
      display: none;

      @include media-breakpoint-down('lg') {
        display: block;
      }

      border-top: 2px solid #{map-get($colors, 'brand-copper')};
      padding-top: #{map-get($spacers, 2)};
    }

    @include media-breakpoint-down('lg') {
      &__ul,
      .header-secondary__ul {
        display: none;

        &.is-visible {
          display: block;
        }
      }

      &__ul,
      .header-secondary__ul {
        margin-bottom: #{map-get($spacers, 2)};
        font-size: 1rem;

        li a {
          display: block;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }
    }

    &-toggler {
      padding: 0.25rem;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='24' viewBox='0 0 30 24'%3e%3cg transform='translate(-329 -14)'%3e%3crect width='30' height='2' transform='translate(329 14)'/%3e%3crect width='30' height='2' transform='translate(329 25)'/%3e%3crect width='30' height='2' transform='translate(329 36)'/%3e%3c/g%3e%3c/svg%3e ");
      background-size: auto 1.25rem;

      &[aria-expanded="true"] {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='19.092' height='19.092' viewBox='0 0 19.092 19.092'%3e%3cg transform='translate(-331.838 -5.642)'%3e%3crect width='25' height='2' transform='translate(333.252 5.642) rotate(45)'/%3e%3crect width='25' height='2' transform='translate(331.838 23.319) rotate(-45)'/%3e%3c/g%3e%3c/svg%3e");
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &__location-menu {
      display: none;

      li {
        a {
          display: block;
          padding-left: 0;
        }

        .fa-external-link {
          margin-left: 0.25em;
          transform: translateY(-0.15rem);
        }
      }

    }

    &__menu {
      display: none;
      margin-bottom: 0.75rem;
      font-size: 1rem;

      @include media-breakpoint-down('lg') {
        display: flex;
      }

      li {
        &:first-child button {
          padding-left: 0;
        }

        &:not(:first-child) {
          border-left: 2px solid #{map-get($colors, 'brand-copper')};
        }

        button {
          font-weight: bold;
          color: #{map-get($colors, 'brand-copper')};

          padding-top: 0.25rem;
          padding-bottom: 0.25rem;

          &.active {
            color: $primary;
          }
        }
      }
    }
  }

  .navbar__search {
    background-color: #F2EDEC;
    border-radius: px2rem(38);
    margin: 1rem 0;
    align-items: center;

    input {
      background-color: transparent;
      border: none;
    }

    .form-outline {
      width: calc(100% - 3.5rem);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  li {
    @include default_li();

    @include media-breakpoint-up(md) {
      &:first-child a {
        padding-left: 0;
      }
    }
  }

  &-search {
    --nav-space: #{1em * divide(9, 8)};

    .btn-close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }

    .modal-header {
      border: none;
      padding: 0;
    }

    .modal-dialog {
      max-width: none;
      background-color: transparent;
    }

    .modal-content {
      border: none;
      box-shadow: 0 3px 50px #00000029;
      padding: var(--theme_outer-space) calc(var(--theme_outer-space) * 2);
      background-color: $white;
      border-radius: 0;
    }

    .input-group {
      background-color: #F2EDEC;
      border-radius: px2rem(38);
      margin-bottom: 1.5rem;
      align-items: center;

      input {
        background-color: transparent;
        border: none;
      }

      button {
        padding: 0;
      }

      .form-outline {
        width: calc(100% - 2rem);
      }

      .fa-search {
        color: map_get($colors, 'brand-copper');
      }
    }

    .ks-loading-icon {
      display: none;
    }

    &__ajax {
      display: grid;

      & >* {
        grid-area: 1 / 1 / 2 / 2;
      }

      .error {
        color: $danger;
      }
    }

    &__result {
      &-count {
        margin-bottom: 2rem;
        color: map_get($colors, 'brand-copper');
        font-size: px2rem(14);
        font-weight: $font-weight-bold;
        display: none;
      }

      &-col {
        font-size: $font-size-2;

        &:not(:last-child) {
          border-right: 1px solid map_get($colors, 'brand-gray');
        }

        h3,
        .h3 {
          @include font-size($font-size-3);

          font-weight: $font-weight-normal;
          color: map_get($colors, 'brand-gray');
          margin-bottom: 2rem;
        }

        a {
          color: $black;
          text-decoration: none;

          @include headlines() {
            font-weight: $font-weight-bold;
            font-size: inherit;
          }

          p {
            margin-bottom: 0;
            font-size: inherit;
          }

          & + a {
            display: block;
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  &.headroom {
    // @see https://wicky.nillia.ms/headroom.js/
    will-change: transform, top;

    &--pinned {
      transform: translateY(0%);
    }

    &--top {
      position: relative;
    }

    @include media-breakpoint-down(lg) {
      top: 0;
      position: sticky;
    }

    &--not-top {
      box-shadow: $box-shadow-sm;

      .navbar {
        height: var(--theme_header-primary-height--collapsed);
      }

      @include media-breakpoint-up(lg) {
        position: sticky;
        animation-name: headerSmoothDown;
        animation-duration: .5s;
        animation-fill-mode: forwards;

        .page-header__logo {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background-color: $white;
            width: calc(100% - 3rem);
            height: 90%;
            z-index: $z-index-1;
          }
        }
      }
    }

    @keyframes headerSmoothDown {
      from {top: -100%;}
      to {top: 0rem;}
    }
  }

  &__content-nav {
    --nav-space-x: #{$font-size-base * 1.5};
    --nav-space-y: #{$font-size-base * 0.5};

    --link-padding-x: calc(var(--nav-space-x) - 0.5rem);
    --link-padding-y: calc(var(--nav-space-y) + 0.25rem);
    --link-font-size: #{$font-size-2};
    --line-height: 1.125;

    --navigation-height: calc((var(--link-font-size) * var(--line-height)) + var(--link-padding-y) * 2);

    height: var(--navigation-height);

    display: block;
    background-color: map_get($colors, 'brand-primary');
    position: relative;
    z-index: 0;
    overflow: hidden;

    transition: bottom 0.25s ease-in-out;

    @include media-breakpoint-up(md) {
      overflow: inherit;
      padding: 0 var(--theme_outer-space);
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      &--is-visible {
        bottom: calc(var(--navigation-height) * -1);
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      text-decoration: none;
      font-size: var(--link-font-size);
      padding: var(--link-padding-y) var(--link-padding-x);
      line-height: var(--line-height);

      &:hover,
      &:focus,
      &.active {
        outline: none;
        color: $white;
      }

      &:hover,
      &:focus,
      &.active {
        opacity: 0.7;
      }

      .fa-external-link {
        margin-left: 0.5em;
        transform: translateY(-0.15rem);
      }

      &.active {
        pointer-events: none;

        @include media-breakpoint-down(md) {
          font-weight: $font-weight-bold;
        }

        .fa {
          color: inherit;
        }
      }

      .fa-extern-link {
        margin-left: 0.25rem;
        transform: translateY(-0.15rem);
      }
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
    }

    li {
      position: relative;

      &:before {
        content: '';

        position: absolute;
        left: 0;
        top: calc(var(--link-padding-y) * 0.9);
        bottom: calc(var(--link-padding-y) * 0.9);
        width: 1px;

        background: white;
        pointer-events: none;
      }

      &:first-child,
      &:last-child {
        &:before {
          display: none;
        }
      }

      &.dots {
        color: $white;

        @include media-breakpoint-up(md) {
          padding-right: 0;
        }
      }
    }

    .content-group__nav-inner {
      width: 100%;
      max-width: map_get($container-max-widths, 'xl');
      margin: 0 auto;
    }

    .dots {
      position: relative;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      &--hide {
        display: none;
      }

      button {
        border: none;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        color: $white;

        padding-left: 0.75rem;
        padding-right: 0.75rem;

        &:hover {
          opacity: 0.6;
        }

        &.show {
          color: $primary;

          position: relative;
          z-index: 1;
          background: $white;

          margin-top: 0.33rem;
          justify-content: flex-start;

          &:hover {
            opacity: 1;
          }

          .fa {
            margin-top: -0.33rem;
          }
        }
      }

      .fa-dot-3 {
        transform: rotate(90deg);
      }

      ul {
        --bs-position: end;

        padding: calc(var(--nav-space-x) / 2) 0;
        position: absolute;
        top: 2.5rem;
        right: 2rem;
        background-color: $white;
        box-shadow: 0 0 50px #00000029;
        display: none;

        &.show {
          display: block;
        }

        li {
          margin-top: 0;
          border: none;
        }

        a {
          color: $black;
          font-size: 0.85rem;
          white-space: nowrap;
          justify-content: flex-start;
          padding: calc(var(--nav-space-x) / 2.5) var(--nav-space-x);

          &:hover,
          &:focus,
          &.active {
            color: $primary;
          }
        }
      }
    }

    .swiper {
      &-slide {
        width: auto;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }

        &--hidden,
        &--mobile {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }

      &-navigation {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom:0;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }

      &-button {
        &-disabled {
          opacity: 0;
        }

        &-prev,
        &-next {
          --theme_swiper-arrow-size: 1.5rem;
          background-image: linear-gradient(#007487, #007487), linear-gradient(to left, rgba(0, 116, 135, 0), #007487);
          background-size: 70% 100%, 30% 100%;
          background-position: left center, right center;
          width: 5rem;
          justify-content: flex-start;
          padding-left: var(--theme_outer-space);
          padding-right: var(--theme_outer-space);

          &:hover,
          &:focus {
            color: $white;
          }
        }

        &-prev {
          width: 3.5rem;
        }

        &-next {
          background-image: linear-gradient(#007487, #007487), linear-gradient(to right, rgba(0, 116, 135, 0), #007487);
          background-position: right center, left center;
          justify-content: flex-end;
        }
      }
    }

    &--hide-on-desktop {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .header-secondary {
    --nav-space: 1em;
    display: none;
    font-size: calc(#{$font-size-1} + 2px);
    font-weight: $font-weight-normal;
    background-color: map_get($colors, 'brand-silver');

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &__ul {
      font-size: calc(#{$font-size-1} + 2px);
      font-weight: $font-weight-normal;
    }

    .page-header {
      &__cols {
        height: var(--theme_header-secondary-height);
        justify-content: flex-end;
      }

      &__nav {
        a {
          padding: 0 var(--nav-space);

          &:hover,
          &:focus {
            text-decoration: underline;
            color: inherit;
          }
        }
      }
    }
  }
}

.page-header,
.page-header-search {
  button,
  input {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}


