.download-container {
  h2,
  .h2 {
    font-weight: 300;
    font-size: $h1-font-size;
  }

  .leaf {
    height: 100%;
  }
}

// .container is needed, because it introduces a padding
.content-section .download-container.container {
  padding-left: 0;
  padding-right: 0;
}
