.floating-contact {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map_get($colors, 'brand-secondary');
  width: px2rem(60);
  height: px2rem(60);
  color: $white;
  border-radius: 100%;
  font-size: 2rem;
  box-shadow: 3px 3px 10px #0000004D;
  text-decoration: none;
  z-index: $zindex-fixed + 1;

  span {
    margin-left: -0.25rem;
    margin-top: 0.25rem;
  }

  &:hover,
  &:focus {
    color: $white;

    span {
      margin-left: -0.25rem;
      margin-top: 0.25rem;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: map_get($colors, 'brand-secondary');
    width: px2rem(60);
    height: px2rem(60);
    color: $white;
    border-radius: 100%;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;

    transition: background-color 0.25s ease-in-out;

    &:hover,
    &:focus {
      background-color: shade-color(map_get($colors, 'brand-secondary'), 15%);
    }
  }

  &__details {
    position: absolute;
    display: none;
    justify-content: center;
    flex-direction: column;
    bottom: 30%;
    right: 60%;
    min-height: 8.1875rem;
    background-color: $white;
    color: $black;
    border-radius: var(--theme_leaf-border-radius) var(--theme_leaf-border-radius) var(--theme_leaf-border-radius);
    box-shadow: 0 0 50px #00000029;
    padding: calc(var(--theme_spacer-size) * 1.25) calc(var(--theme_spacer-size) * 1.5);
    z-index: 1;
    font-size: $font-size-base;

    @include media-breakpoint-down(md) {
      min-width: 19.5rem;
      bottom: 55%;
      right: -8%;
      padding: calc(var(--theme_spacer-size) * 1) calc(var(--theme_spacer-size) * 1.25);
    }

    a {
      font-weight: $font-weight-bold;
      word-break: break-word;

      & + & {
        margin-top: 0.5rem;
      }

      &:hover,
      &:focus {
        margin: 0;
      }
    }

    .icon-block {
      --icon-width: 1.5em;

      @include media-breakpoint-up(md) {
        white-space: nowrap;
      }
    }

    &--active {
      display: flex;
    }
  }
}
