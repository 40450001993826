.contact {
  &__headline,
  &__step-headline {
    display: block;
    width: 100%;
    text-align: center;
  }

  &__headline {
    @include font-size($font-size-6);
  }

  &__step {
    margin-top: 3.25rem;

    &:not(:first-child) {
      display: none;
    }

    &-result {
      display: none;

      input {
        cursor: pointer;
      }

     .contact-form__input {
      width: auto !important;

       label {
         margin-bottom: 1.5rem;
       }
     }
    }

    &-headline {
      margin-bottom: 1.5rem;
      font-weight: $font-weight-light;
      @include font-size($font-size-3);
    }

    .locations-catalog__sector {
      margin-top: 0;

      > .row {
        justify-content: center;
      }
    }

    &-buttons {
      @include media-breakpoint-up('md') {
        max-width: map_get($container-deviant-sizes, 'md');
        margin: calc(var(--bs-gutter-y) * -1) auto 0;
        justify-content: center;
      }

      .btn {
        border: 1px solid transparent;
        color: $black;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);

        &:not(.active):not(:hover){
          background: $white;
        }

        .btn-outline-danger {
          background: #{map-get($theme-colors, 'danger')};
        }

        &:hover,
        &.active {
          color: white;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0);

          .fa {
            color: white;
          }
        }

        .fa {
          color: map_get($colors, 'brand-copper');
        }
      }
    }
  }
}

.contact-form {
  --input-icon-with: 4rem;
  --space-y: 2.25rem;
  --fieldPadding-y: 0.45rem;
  --fieldPadding-x: 1rem;
  --fieldBgColor: #{$white};
  --fieldHoverBgColor: #{map_get($colors, 'brand-silver')};

  --fieldColor: #{map_get($colors, 'brand-copper')};
  --inputBorder: #{px2rem(38)};
  --textareaBorder: #{px2rem(25)};
  --bs-gutter-y: var(--space-y);
  --formBgColor: #{map_get($colors, 'brand-silver')};

  &--silver {
    --formBgColor: #{$white};
    --fieldBgColor: #{map_get($colors, 'brand-silver')};
    --fieldHoverBgColor: #{map_get($colors, 'brand-silver')};

  }

  background-color: var(--formBgColor);

  &__headline {
    margin-bottom: var(--bs-gutter-y);
  }

  label {
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: $font-size-2;

    @include media-breakpoint-up(md) {
      font-size: $font-size-3;
    }
  }

  input,
  .custom-select-opener {
    background-color: var(--fieldBgColor);
    color: var(--fieldColor);
    border-radius: var(--inputBorder);
    border-color: var(--fieldBgColor);
    padding: var(--fieldPadding-y) var(--fieldPadding-x);
  }

  textarea {
    background-color: var(--fieldBgColor);
    color: var(--fieldColor);
    padding: var(--fieldPadding-y) var(--fieldPadding-x);
    border-radius: 0 var(--textareaBorder);
    border-color: var(--fieldBgColor);
    min-height: px2rem(200);
  }

  .custom-select-opener {
    background-image: linear-gradient(var(--fieldBgColor), var(--fieldBgColor)), linear-gradient($primary, $primary);
    background-size: calc(100% - var(--input-icon-with)) 100%, var(--input-icon-with) 100%;
    background-repeat: no-repeat;
    background-position: left center, right center;
    padding-right: var(--input-icon-with);

    &::before {
      content: '\E905';
      @include fontello();
      color: $white;
      position: absolute;
      top: 0.5rem;
      right: 1.75rem;
      transform: rotate(90deg);
      transition: transform .5s;
      transform-origin: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .custom-select {
    &-container {
      &.is-open {
        .custom-select-opener {
          &::before {
            transform: rotate(-90deg);
          }
        }

        .custom-select-panel {
          overflow: hidden;

          &--overflow {
            overflow-y: auto;
          }
        }
      }
    }

    &-panel {
      border-radius: 0 0 px2rem(25) px2rem(25);
      background-color: $white;
      box-shadow: 5px 5px 20px #00000029;
      width: calc(100% - (#{var(--fieldPadding-x)} * 2));
      left: 50%;
      transform: translateX(-50%);
    }

    &-option {
      padding: var(--fieldPadding-y) var(--fieldPadding-x);

      &:last-child {
        padding-bottom: var(--fieldPadding-x);
      }

      &.has-focus {
        background-color: transparent;
      }

      &:hover {
        cursor: pointer;
        background-color: var(--fieldHoverBgColor);
      }

      &.is-selected {
        color: $white;
        background-color: $primary;

        &::before {
          content: none;
        }
      }
    }
  }

  &__optional {
    font-size: 0.7rem;
    transform: translateY(-0.25rem);
    display: inline-block;

    margin-left: 0.5rem;
  }

  // Lock input width to 50% for now
  @include media-breakpoint-up(md) {
    &__input {
      width: 50% !important;
      flex-grow: 0 !important;

      padding-right: calc(var(--space-y) * 0.5);
      margin-right: calc(var(--space-y) * -1 * 0.5);
    }
  }

  &__input,
  &__textarea {
    display: flex;
    flex-direction: column-reverse;
  }

  &__contact-options {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__headline {
      font-weight: 300;
      margin-bottom: 1.5rem;

      font-size: $font-size-3;
    }

    &__table {
      display: table;
    }

    &__row {
      display: table-row;
      font-size: $font-size-2;

      @include media-breakpoint-up(md) {
        @include font-size($font-size-3);
      }

      & + & > * {
        padding-top: map-get($spacers, 4);
      }

      > div {
        display: table-cell;
      }

      &__icon {
        width: 3rem;
        text-align: center;

        padding-right: map-get($spacers, 3);

        @include icon-normalize(false);
      }

      &__value {
        @include break-word();

        text-align: left;
        vertical-align: top;

        a {
          text-decoration: none;
          color: $black;

          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
      }


    }

    .fa {
      font-size: $font-size-3;

      @include media-breakpoint-up(md) {
        @include font-size($font-size-5);
      }
    }
  }

  &__line {
    text-align: center;
    margin: 2rem 0;
    background-image: linear-gradient($body-color, $body-color);
    background-position: center center;
    background-size: 100% 1px;

    span {
      font-weight: bold;
      padding: 0.5rem 1.25rem;
      background-color: var(--formBgColor);
    }
  }

  &__input {
    &--with-icon {
      input {
        background-image: linear-gradient(var(--fieldBgColor), var(--fieldBgColor)), draw_pencil($white), linear-gradient($primary, $primary);
        background-size: calc(100% - var(--input-icon-with)) 100%, 1.25rem auto, var(--input-icon-with) 100%;
        background-repeat: no-repeat;
        background-position: left center, right 1.5rem center, right center;
        padding-right: var(--input-icon-with);
      }
    }

    & + .contact-form__textarea {
      margin-top: var(--space-y);
    }
  }

  // Correction needed to align adjusted columns with `col-` correctly.
  @include media-breakpoint-up(md) {
    &__input,
    &__textarea,
    &__checkbox {
      &[class*='col-'] {
        padding-right: calc(var(--space-y) * 0.5);
        margin-right: calc(var(--space-y) * -1 * 0.5);
      }
    }
  }

  &__textarea {
    & + &,
    & + .contact-form__row,
    & + .contact-form__input,
    & + .contact-form__checkbox {
      margin-top: var(--space-y);
    }
  }

  &__row {
    & + &,
    & + .contact-form__textarea,
    & + .contact-form__checkbox,
    & + .contact-form__input {
      margin-top: var(--space-y);
    }

    display: flex;
    flex-direction: column;
    gap: var(--space-y);

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    > .contact-form__input,
    > .contact-form__textarea,
    > .contact-form__checkbox {
      &:not([class*='col-']) {
        flex-grow: 1;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .form-check-input[type="checkbox"] {
    transform: scale(1);
    padding: 0.5rem;
    cursor: pointer;
  }

  &__checkbox {
    & + &,
    & + .contact-form__textarea,
    & + .contact-form__row {
      margin-top: var(--space-y);
    }

    label {
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

  [type='submit'] {
    position: relative;
    width: 11rem;
    margin-top: 3rem;

    &::before {
      content: '\E905';
      @include fontello();
      color: $white;
      position: absolute;
      top: $input-btn-padding-y;
      right: $input-btn-padding-x;
    }
  }

  &-success {
    display: none;

    &__headline {
      margin-bottom: 2.25rem;
    }

    .btn {
      margin-top: 2.25rem;
    }
  }

  &__error {
    color: $danger;
  }
}
