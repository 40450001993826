.page-article {
  --gutter-x: #{map_get($spacers, 5)};
  @include break-word-hyphens();

  & + & {
    margin-top: var(--theme_section-padding);
  }

  &__col {
    & + & {
      margin-top: calc(var(--gutter-x) / 2);

      @include media-breakpoint-up(md) {
        padding-left: calc(var(--gutter-x) / 2 + .5rem);
      }

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }

  &__image {
    background-color: map_get($colors, 'brand-silver');
    padding-top: percentage(divide(5, 7));

    border-radius: var(--theme_img-border-radius);
  }

  &__title {
    @include font-size($font-size-6);
    color: map-get($theme-colors, 'primary');

    @include headlines() {
      font-size: inherit;
    }

    &:hover {
      color: shade-color(map-get($theme-colors, 'primary'), 20%);
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__header {
    margin-bottom: ($font-size-base * 1.25);
  }

  &__meta {
    color: map_get($colors, 'brand-copper');
    font-size: $font-size-2;
    font-weight: $font-weight-light;
    margin-bottom: $font-size-base;

    a {
      color: inherit;
      text-decoration: none;
      @include break-word();
      white-space: nowrap;

      &:hover,
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }

    > div {
      display: inline;
    }
  }

  &__text {
    margin-bottom: ($font-size-base * 2.5);
  }

  &__categories {
    & > *:not(:last-child) {
      margin-right: 0.25rem;
    }
  }

  & + .pagination-container {
    margin-top: var(--theme_section-padding);
  }
}
