// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.content-section {
  --padding-y: var(--theme_section-padding);
  --padding-top: var(--padding-y);
  --padding-bottom: var(--padding-y);
  --padding-x: var(--theme_outer-space);
  --push-size: calc(var(--theme_section-padding) * -3);

  --bird-color: #{$white};
  --bird-opacity: #{divide(5, 16)};

  padding: var(--padding-top) var(--padding-x) var(--padding-bottom);
  position: relative;

  &--push {
    &-up {
      --padding-top: 0;
      margin-top: var(--push-size);

      z-index: $z-index-3;
    }
    &-down {
      --padding-bottom: 0;
      margin-bottom: var(--push-size);

      z-index: $z-index-3;
    }
  }

  &--fade-out {
    mask-image: linear-gradient(to right,
      transparent percentage(divide(3, 64)),
      black percentage(divide(10, 64)),
      black percentage(divide(54, 64)),
      transparent percentage(divide(61, 64)));

    @media (max-width: 1600px) {
      mask-image: none;
    }
  }

  &--justify-left + &--justify-left {
    padding-top: 0;
  }

  &--justify-left {
    > .container-fluid {
      position: relative;
      > .container-fluid {
        margin-left: 0;
      }
    }
  }

  &__bird {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    &__bird {
      --bird-size: max(250px, calc((100vw - #{map-get($container-max-widths, 'lg')}) / 2));

      display: block;
      position: absolute;
      top: calc(var(--theme_section-padding) * 0.5);
      right: 0;

      width: var(--bird-size);
      height: var(--bird-size);
      overflow: hidden;

      svg {
        stroke: var(--bird-color);
        stroke-width: 4px;
        opacity: var(--bird-opacity);

        transform: translateX(10%);
      }
    }

    @each $background, $overwrites in $text-color-overwrites {
      $bird-color: map-get($overwrites, 'bird-color');
      $bird-opacity: map-get($overwrites, 'bird-opacity');

      &.bg-#{$background} {
        @if $bird-color != null {
          --bird-color: #{$bird-color};
        }

        @if $bird-opacity != null {
          --bird-opacity: #{$bird-opacity};
        }
      }
    }
  }

  @include headlines() {
    &:only-child {
      margin-bottom: 0;
    }
  }

  ul,
  p {
    &:only-child {
      margin-bottom: 0;
    }
  }

  &__title {
    --title-margin-bottom: calc(var(--padding-top) / 2);
    --title-padding-bottom: var(--title-margin-bottom);
    --title-extra-margin-bottom: 0px;
    --title-tile-height: 20rem;

    @include media-breakpoint-up(lg) {
      --title-tile-height: 40rem;
    }

    @each $name, $color in $theme-colors {
      &.bg-#{$name} {
        --title-margin-bottom: var(--padding-top);
      }
    }

    margin: calc(var(--padding-top) * -1) calc(var(--padding-x) * -1) var(--title-extra-margin-bottom);
    padding: var(--padding-top) var(--padding-x) var(--title-padding-bottom);

    &__inner {
      @include font-size($font-size-5);
      font-weight: bold;
      line-height: 1.25;

      @include headlines {
        @include font-size($font-size-8);
        font-weight: inherit;
        line-height: inherit;
      }

      p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      .row:not(:last-child) {
        margin-bottom: var(--padding-top);
      }

      .tile {
        min-height: var(--title-tile-height);
      }
    }

    &--has-tile-overflow {
      --title--tile-overflow: calc(var(--title-tile-height) / 2);

      --title-padding-bottom: 0;
      --title-extra-margin-bottom: calc(var(--title--tile-overflow) + var(--title-margin-bottom)); // Margin bottom has to be added to increase spacing another bit
    }

    &--has-tile-overflow &__inner .tile {
      margin-bottom: calc(var(--title--tile-overflow) * -1);
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .content-section {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .content-section {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .content-section {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .content-section {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .content-section {
  }

}
