// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.topics-catalog {
  &:not(.busy) {
    .show-while-busy {
      display: none;
    }
  }

  .leaf {
    height: 100%;
  }

  &__search {
    &-error {
      font-size: $font-size-2;
      color: $danger;
      margin-top: ($font-size-base * 0.75);
    }

    .input-group {
      background-color: #F2EDEC;
      border-radius: 2.375rem;
      margin-bottom: 1.5rem;
      align-items: center;
    }

    .form-control {
      padding-right: 2.75rem;
      background-color: transparent;
      border: none;
      border-radius: 2.375rem !important; // Important is needed because of form-group setting some to 0
    }

    .btn {
      position: absolute;
      right: 0;
      z-index: $z-index-1;
      border: none;
      background-color: transparent;

      .fa {
        color: #95918C;
      }
    }
  }

  &__group-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-5;
  }

  &__group-btn > div > div:first-child {
    line-height: 1;
  }

  &__group-btn.btn-outline-primary,
  &__group-reset button, {
    box-shadow: 0 0 10px #00000019;
    border: none;
    color: $black;

    &.active:disabled {
      background: $primary;
      box-shadow: 0 0 10px #00000019;
    }

    &:hover,
    &:focus,
    &.active {
      color: $white;
      box-shadow: none;

      .fa {
        color: $white;
      }
    }
  }

  &__group-reset {
    button {
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;

      .fa {
        transition: color .15s ease-in-out;
        vertical-align: -11%;
      }

      &.btn-outline-danger {
        &:active,
        &:focus {
          box-shadow: none;
          background-color: transparent;
          border-color: transparent;
          color: map_get($colors, 'brand-secondary');

          .fa {
            color: map_get($colors, 'brand-secondary');
          }
        }
      }


      &:hover {
        background-color: $white;
        color: map_get($colors, 'brand-secondary');

        .fa {
          color: map_get($colors, 'brand-secondary');
        }
      }
    }
  }

  &__group:not(.d-none) {
    & ~ & {
      margin-top: calc(var(--theme_spacer-size) * 5);
    }
  }

  &.busy {
    .hide-while-busy {
      display: none;
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .topics-catalog {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .topics-catalog {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .topics-catalog {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .topics-catalog {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .topics-catalog {
  }

}
