.author-card {
  --img-distance: 2rem;
  --img-width: 12.5rem;

  background-color: map_get($colors, 'brand-silver');
  text-align: center;

  @include media-breakpoint-up(md) {
    --img-distance: 0;
    text-align: left;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: map_get($container-deviant-sizes, 'lg');
    margin: 0 auto;
    padding-top: ($font-size-base * 2.5);
    padding-bottom: ($font-size-base * 2.5);
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__col {
    &--image {
      width: var(--img-width);
      margin-bottom: var(--img-distance);

      @include media-breakpoint-up(md) {
        margin-right: ($font-size-base * 3);
      }
    }
  }

  &__image {
    width: var(--img-width);
    border-radius: 50%;
    padding-top: 100%;
    background-color: rgba($black, divide(1, 8));
  }
}
