// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.big-leaf {
  @include bigleaf-background();

  --nav-space-x: #{$font-size-base * divide(17, 8)};
  --nav-space-y: #{$font-size-base * 0.5};

  @include break-word();
  font-size: $font-size-2;

  > * {
    position: relative;
  }

  &__nav {
    display: none;
    padding-top: #{$font-size-base * 2.75};

    .fa {
      color: map_get($colors, 'brand-copper');
      font-size: $font-size-5;
      margin-right: ($font-size-base * divide(7, 8));
    }

    button {
      @include reset-button();
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $font-weight-bold;
      color: $body-color;
      padding: ($font-size-base * 0.25) var(--nav-space-x);

      &:hover,
      &:focus,
      &.active {
        outline: none;
        color: $primary;
      }

      &.active {
        pointer-events: none;

        .fa {
          color: inherit;
        }
      }
    }

    ul {
      display: flex;
      //flex-wrap: wrap;
      list-style: none;
      margin: calc(var(--nav-space-y) * -1) calc(var(--nav-space-x) * -1) 0;
      padding: 0;
    }

    li {
      margin-top: var(--nav-space-y);
      border-right: 1px solid map_get($colors, 'brand-copper');

      &:last-child {
        border-right: none;
      }

      &.swiper-slide {
        width: auto;
      }
    }

    .swiper {
      &-navigation {
        margin-top: 2.75rem;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }

      &-button {
        &-disabled {
          opacity: 0;
        }

        &-prev,
        &-next {
          --theme_swiper-arrow-size: 1.5rem;
          background-image: linear-gradient(map_get($colors, 'brand-silver'), map_get($colors, 'brand-silver')), linear-gradient(to left, rgba(242, 237, 236, 0), map_get($colors, 'brand-silver'));
          background-size: 25% 100%, 75% 100%;
          background-position: left center, right center;
          width: 7rem;
          height: 2.5rem;
          justify-content: flex-start;
          padding-left: var(--theme_outer-space);
          padding-right: var(--theme_outer-space);
          color: map_get($colors, 'brand-petrol');
          text-shadow: none;

          &:focus,
          &:hover {
            color: var(--theme-btn-hover-color);
          }
        }

        &-prev {
          left: 0;
          padding-left: 0;
        }

        &-next {
          right: 0;
          padding-right: 0;
          background-image: linear-gradient(map_get($colors, 'brand-silver'), map_get($colors, 'brand-silver')), linear-gradient(to right, rgba(242, 237, 236, 0), map_get($colors, 'brand-silver'));
          background-position: right center, left center;
          justify-content: flex-end;
        }
      }
    }
  }

  &__inner {
    padding: #{$font-size-base * 4} 0;
    color: $body-color;

    p,
    ul,
    ol {
      font-size: inherit;
      font-weight: inherit;
    }

    .swiper-wrapper {
      display: grid;

      & > * {
        grid-area: 1 / 1 / 2 / 2;
      }
    }

    @include headlines() {
      color: map-get($theme-colors, 'brand-petrol');
    }
  }

  &__tab {
    display: none;

    &:first-child {
      display: block;
    }
  }

  &--tabbed {
    .big-leaf {
      &__nav {
        display: block;
        overflow: hidden;
      }
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .big-leaf {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .big-leaf {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .big-leaf {
    --slide-width: 95%;

    &:before {
      border-radius: 0;
    }

    &__tab {
      &:only-child {
        --slide-width: 100%;
      }

      width: var(--slide-width);

      padding: calc(var(--theme_spacer-size)*2) calc(var(--theme_spacer-size)*2);

      background: white;

      border-top-right-radius: var(--theme_leaf-border-radius);
      border-bottom-left-radius: var(--theme_leaf-border-radius);

      box-shadow: 0 0 var(--theme_leaf-shadow-size) rgba($black, divide(1, 10));
    }

    &--tabbed {
      .big-leaf {
        // Keep navigation hidden on mobile
        &__nav {
          display: none;
        }
      }

      .big-leaf__inner.swiper-container-initialized {
        .big-leaf__tab {
          display: flex;
          flex-direction: column;
          height: auto;

          &--grid-mobile {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 0.5fr auto 1fr auto;
            grid-template-areas: 'headline' 'headline' 'hr-list' 'button';

            & > :first-child {
              grid-area: headline;
            }

            & > :nth-child(2) {
              grid-area: hr-list;
            }

            & > :nth-child(3) {
              grid-area: button;
            }
          }
        }
      }
    }

    .swiper-navigation {
      display: flex;
      justify-content: center;

      > .swiper-navigation__inner {
        width: var(--slide-width);
      }
    }

    .swiper-wrapper {
      display: flex;
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .big-leaf {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .big-leaf {
  }

}
