// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.page-main {
  --meta-gutter: #{$font-size-base * 3};

  flex-grow: 1;
  padding: var(--theme_section-padding) 0;

  //&__header {
  //  padding: 0 var(--theme_outer-space);
  //}

  &__meta {
    padding: 0 var(--theme_outer-space);
    font-size: $font-size-1;
    color: map_get($colors, 'brand-copper');

    &-inner {
      display: flex;
      margin-right: calc(var(--meta-gutter) / -2);
      margin-left: calc(var(--meta-gutter) / -2);
    }

    &-col {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--meta-gutter) / 2);
      padding-left: calc(var(--meta-gutter) / 2);

      &--date {
        flex-grow: 0;
        flex-basis: auto;
        width: auto;
      }
    }

    * + & {
      margin-top: ($font-size-base * 1.5);
    }
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .page-main {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .page-main {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .page-main {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .page-main {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .page-main {
  }

}
