// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  opacity: 0;
  top: 0;
  font-size: var(--theme_swiper-arrow-size);
  width: var(--theme_swiper-arrow-width);
  height: 100%;
  margin-top: 0;
  color: $white;
  transition: opacity ($beat * 2);
  cursor: auto;
  pointer-events: none;
  &:after {
    @include fontello();
  }
  &:hover,
  &:focus {
    color: $primary;
    text-shadow: none !important;
  }
  .swiper-container-initialized &,
  .swiper-container-initialized ~ & {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto !important;
    pointer-events: none !important;
  }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next,
.swiper-container-rtl ~ .swiper-button-next {
  &:after {
    content: '\e904';
  }
  left: 0;
  text-shadow: 1px 0 2px rgba($black, divide(3, 8));
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-container-rtl ~ .swiper-button-prev {
  &:after {
    content: '\e905';
  }
  right: 0;
  text-shadow: -1px 0 2px rgba($black, divide(3, 8));
}

.swiper-pagination {
  &-bullet {
    --swiper-pagination-color: #ffffff;
    border-radius: 2rem;
    background: #ffffff;
    opacity: 0.5;

    width: 1rem;
    height: 6px;

    &-active {
      width: 3rem;
      opacity: 1;
    }
  }
}

.swiper {

  &--navigation-below {

    .swiper {
      &-navigation {
        display: none;
        margin-top: var(--theme_spacer-size);

        &__inner {
          display: flex;
          justify-content: flex-end;

        }
      }
    }

    // When navigation is setup...
    &.swiper-container-initialized {

      // ...then show navigation below
      .swiper-navigation {
        display: flex;
      }

    }

    .swiper-button-prev,
    .swiper-button-next {
      --arrow-color: #{map-get($colors, 'brand-copper')};
      --arrow-color--hover: #{map-get($colors, 'brand-petrol')};
      --arrow-color--disabled: #{lighten(map-get($colors, 'brand-copper'), 25%)};

      @each $background, $overwrites in $text-color-overwrites {
        $text-override: map-get($overwrites, 'text');

        @if $text-override != null {
          @at-root .bg-#{$background} & {

            --arrow-color: #{$text-override};

            --arrow-color--hover: #{darken($text-override, 10%)};
            --arrow-color--disabled: #{lighten($text-override, 25%)};
          }
        }
      }

      position: relative;
      top: unset;
      bottom: unset;

      width: var(--theme_swiper-arrow-width);
      height: var(--theme_swiper-arrow-width);

      font-size: 1.25rem;
      text-shadow: none;

      color: var(--arrow-color);

      border: 2px solid var(--arrow-color);
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: $spacer;
      }

      &:active,
      &:hover {
        --arrow-color: var(--arrow-color--hover);
      }

      &.swiper-button-disabled {
        --arrow-color: var(--arrow-color--disabled);
      }
    }
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {
}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {
}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {
}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {
}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {
}
