.doctolib {
  $doctolib-color: #0596DE;

  position: fixed;
  bottom: 6.5rem;
  right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $doctolib-color;
  width: px2rem(60);
  height: px2rem(60);
  color: $white;
  border-radius: 100%;
  font-size: 2rem;
  box-shadow: 3px 3px 10px #0000004D;
  text-decoration: none;
  z-index: $zindex-fixed;
  background-image: url("images/doctolib-white-transparent.png");
  background-size: auto 15px;
  background-position: center center;
  background-repeat: no-repeat;

  transition: background-color 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: $white;
    background-color: shade-color($doctolib-color, 15%);
  }
}
