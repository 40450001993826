@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(50%, 0, 0);
  }
  33% {
    transform: none;
  }
  100% {
    opacity: 1;
  }
}
