.social-share {

  &__inner {
    --bs-gutter-y: 0.5rem;
    margin-top: 0;
  }

  span {
    color: map_get($colors, 'brand-copper');
  }
}