.contact-container {
  $self: &;

  @include bigleaf-background();

  padding-top: calc(var(--theme_outer-space) * 2);
  padding-bottom: calc(var(--theme_outer-space) * 2);

  &__contact-group {
    #{$self} &__title {
      color: $black;
      font-size: ($font-size-3 * 1.1);
      font-weight: $font-weight-bold;

      margin-bottom: map-get($spacers, 2);
    }
  }

  &__link-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: inherit;

    > li:not(:first-child) {
      margin-top: $spacer * 0.5;
    }

    a {
      color: inherit;
      font-weight: $font-weight-normal;

      &:hover,
      &:focus,
      &:active {
        opacity: 0.75;
      }
    }
  }

  .icon-block {
    display: flex;
  }

  &__main {
    color: $body-color;

    @include media-breakpoint-up(md) {
      padding-top: calc(#{$font-size-2} * var(--bs-body-line-height) + #{$font-size-2});
    }

    &__headline {
      margin-top: 0;
    }

    p {
      font-size: $font-size-2;

      @include media-breakpoint-up(md) {
        font-size: $font-size-3;
      }
    }
  }

  &__aside {
    color: $body-color;

    &__headline {
      margin-top: 0;
    }

    &__subheadline {
      font-size: $font-size-2;
      margin-bottom: $font-size-2;
    }

    .content-image {
      margin-top: $spacer;
      margin-bottom: $spacer * 2.5;

      img {
        border-radius: 100%;
        width: 50%;
      }
    }

    .icon-block__icon {
      align-items: baseline;
    }
  }

  // Remove padding introduced by container class
  &.container {
    padding-left: 0;
    padding-right: 0;
  }

  &__contact-group:not(:first-child) {
    margin-top: $spacer * 2.5;
  }

  @include headlines {
    @include font-size($font-size-5);
    color: $primary;
  }
}
