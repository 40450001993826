.content-image {
  //todo gucken, wie wir am selektieren, dass das ein Abstand nach oben und unten haben soll. Evtl über eine Containerklasse. Wird z.B bei Blogbeiträge gebraucht
  //margin-top: var(--theme_outer-space);
  //margin-bottom: var(--theme_outer-space);

  figcaption {
    max-width: 100%;
  }

  img {
    border-radius: var(--theme_img-border-radius);
  }

  &--oversize {
    img {
      @include media-breakpoint-up(xl) {
        width: calc(100% + var(--theme_overflow-size) * 2);
        max-width: calc(100% + var(--theme_overflow-size) * 2);
        margin-left: calc(var(--theme_overflow-size) * -1);
      }
    }
  }

  &--decoration {
    position: relative;

    &::before {
      @include media-breakpoint-up(md) {
        @include fontello();
        content: '\e90b';
        color: #CFD0D0;
        line-height: inherit;
        position: absolute;
        font-size: px2rem(230);
        bottom: calc(#{px2rem(230)} / 2 * -1);
        left: calc(#{px2rem(230)} / 2 * -1);
      }

      @include media-breakpoint-up(lg) {
        font-size: px2rem(260);
        bottom: calc(#{px2rem(260)} / 2 * -1);
        left: calc(#{px2rem(260)} / 2 * -1);
      }

      @include media-breakpoint-up(xl) {
        font-size: px2rem(305);
        bottom: calc(#{px2rem(305)} / 2 * -1);
        left: calc(#{px2rem(305)} / 2 * -1);
      }
    }

    &-top_right {
      &::before {
        @include media-breakpoint-up(md) {
          top: calc(#{px2rem(320)} / 2 * -1);
          right: calc(#{px2rem(320)} / 2 * -1);
          bottom: inherit;
          left: inherit;
        }

        @include media-breakpoint-up(lg) {
          top: calc(#{px2rem(260)} / 2 * -1);
          right: calc(#{px2rem(260)} / 2 * -1);
        }

        @include media-breakpoint-up(xl) {
          top: calc(#{px2rem(305)} / 2 * -1);
          right: calc(#{px2rem(305)} / 2 * -1);
        }
      }
    }
  }
}
