@use 'sass:map';
@use 'sass:list';

// =====================================================================================================================
// Containers
// =====================================================================================================================

.container-fluid {
  --fluid-wrapper-width: map.get($container-max-widths, 'xl');
  max-width: map.get($container-max-widths, 'xl');

  @each $name, $width in $container-deviant-sizes {
    &--#{$name} {
      --fluid-wrapper-width: #{$width};
      max-width: $width;
    }
  }
}


.stretch-to-container {
  $width: map.get($container-max-widths, 'xl');
  --container-width: min(#{$width}, 100vw - (var(--theme_outer-space) * 2));
  --missing-width: max(calc(var(--container-width) - 100%), 0px);
  --stretch-width: max(calc(var(--missing-width) / 2), 0px);

  margin-left: calc(var(--stretch-width) * -1);
  margin-right: calc(var(--stretch-width) * -1);
  max-width: $width;
}

@each $name, $width in $container-deviant-sizes {
  .stretch-to-container-#{$name} {
    --container-width: min(#{$width}, 100vw - (var(--theme_outer-space) * 2));
    --missing-width: max(calc(var(--container-width) - 100%), 0px);
    --stretch-width: max(calc(var(--missing-width) / 2), 0px);

    margin-left: calc(var(--stretch-width) * -1);
    margin-right: calc(var(--stretch-width) * -1);
    max-width: $width;
  }
}

.container,
.container-fluid {
  .row--items-full-height {
    @extend %row-items-full-height;

    > * {
      display: flex;
      flex-direction: column;

      > .row {
        flex-grow: 1;
      }
    }
  }
}

// =====================================================================================================================
// Typography
// =====================================================================================================================

.text-brand-gray {
  color: map_get($colors, 'brand-gray') !important;
}

.text-brand-copper {
  color: map_get($colors, 'brand-copper') !important;
}

.fw-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

.fw-extra-bold {
  font-weight: $font-weight-extra-bold !important;
}

// =====================================================================================================================
// Buttons
// =====================================================================================================================

.btn {
  &-secondary {
    color: $white !important;
  }
  &-sm {
    font-weight: $font-weight-normal;
  }
  &-round {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125em;
    height: 3.125em;
    padding: 0;
    line-height: 1;
  }
}


// =====================================================================================================================
// Text Color Overwrites
// =====================================================================================================================

@each $background, $overwrites in $text-color-overwrites {
  $text-color: map-get($overwrites, 'text');
  $link-color: map-get($overwrites, 'links');

  .bg-#{$background} {
    @if $text-color != null {
      color: $text-color;
    }

    a {
      @if $link-color != null {
        color: $link-color;

        &:hover {
          color: darken($link-color, 5%);
        }
      }
    }
  }
}
