.virtuelle-tour {
  min-height: 100vh;
  display: grid;
  align-items: center;

  & > * {
    grid-area: 1 / 1 / 2 / 2;
  }

  h2,
  .h2,
  h3,
  .h3 {
    font-weight: 300;
    font-size: px2rem(40);
  }

  p {
    @include font-size(px2rem(20));
  }

  &__bg,
  &__overlay {
    width: 100%;
    height: 100%;
  }

  &__overlay {
    background-color: rgba(0,0,0,0.5);
  }

  &__content {
    padding: var(--theme_outer-space);
    margin: 0 auto;
    display: block;
    color: $white;
    text-align: center;
  }

  .fa-360 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }

  .btn {
    margin-top: 1rem;
    background-color: $danger;
    border-color: $danger;
    color: $white;

    &:hover,
    &:focus {
      background-color: darken($danger, 20%);
      border-color: darken($danger, 20%);
    }
  }

  &-modal {
    .modal-dialog {
      background-color: transparent;
      max-width: none;
      padding-left: var(--theme_outer-space);
      padding-right: var(--theme_outer-space);
      overflow: hidden;
    }

    .modal-body {
      padding: 0;
    }

    .ratio {
      min-height: 15rem;
      max-height: calc(100vh - 7rem);
    }

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
