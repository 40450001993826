$separator-width: 3.5em;
$size-factor: divide(9, 16);

.breadcrumb-container {
  font-size: $font-size-2;
  font-weight: $font-weight-light;

  & + .content-section,
  & + .content-with-sidebar .content-section:first-child,
  & + .blog-container .content-section:first-child,
  & + .content-group .content-group__nav {
    padding-top: 0;

    @each $colorname, $color in $theme-colors {
      &.bg-#{$colorname} {
        padding-top: var(--theme_section-padding);
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 1rem;
    }
  }

  &__menu {
    display: inline-block;
    max-width: 100%;
    color: map_get($colors, 'brand-copper');

    a {
      color: inherit;
      text-decoration: none;
      display: block;
      padding: divide(($separator-width * $size-factor), 4) 0;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        color: $primary;
        outline: none;
      }
    }

    ol {
      display: inline-flex;
      align-items: center;
      list-style: none;
      margin: 1.5rem 0;
      padding: 0;
      max-width: 100%;
      font-size: inherit;
      font-weight: inherit;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }

    li {
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        margin-right: divide(($separator-width * $size-factor), 2);
        overflow: inherit;

        // Prevents cutoff kirinus logo
        i {
          margin-left: 1px;
        }
      }

      + li {
        padding-left: ($separator-width * $size-factor);
        padding-right: divide(($separator-width * $size-factor), 2);

        &:before {
          @include fontello();
          content: '\e900';
          position: absolute;
          top: 0;
          left: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: percentage($size-factor);
          height: 100%;
          opacity: divide(7, 8);
        }
      }
    }
  }

  &__date {
    font-size: inherit;
    font-weight: inherit;
    color: map_get($colors, 'brand-copper');
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.5rem;
    }
  }
}

.page-header-slider--plate {
  & + .breadcrumb {
    .container-fluid {
      max-width: calc(#{map_get($container-deviant-sizes, 'md')} + (var(--theme_outer-space) * 2));
    }
  }

  & + .breadcrumb-container {
    width: 100%;
    max-width: calc(#{map_get($container-deviant-sizes, 'md')} + (var(--theme_outer-space) * 4));
    margin-left: auto;
    margin-right: auto;
  }
}
