// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------


// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.split-content {
  --gutter: #{$font-size-base * 3};
  --col-1-width: #{percentage(divide(6, 12))};
  --col-2-width: #{percentage(divide(6, 12))};

  display: flex;
  margin-right: calc(var(--gutter) / -2);
  margin-left: calc(var(--gutter) / -2);

  &__col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 100%;
    padding-right: calc(var(--gutter) / 2);
    padding-left: calc(var(--gutter) / 2);

    &--1 {
      width: var(--col-1-width);
    }

    &--2 {
      width: var(--col-2-width);
    }
  }

  &--4-8 {
    --col-1-width: #{percentage(divide(4, 12))};
    --col-2-width: #{percentage(divide(8, 12))};
  }

  &--8-4 {
    --col-1-width: #{percentage(divide(8, 12))};
    --col-2-width: #{percentage(divide(4, 12))};
  }

  &--5-7 {
    --col-1-width: #{percentage(divide(5, 12))};
    --col-2-width: #{percentage(divide(7, 12))};
  }

  &--7-5 {
    --col-1-width: #{percentage(divide(7, 12))};
    --col-2-width: #{percentage(divide(5, 12))};
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// lg
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('lg') {

  .split-content {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// md
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('md') {

  .split-content {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// sm
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('sm') {

  .split-content {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// xs
// ---------------------------------------------------------------------------------------------------------------------

@include media-breakpoint-down('xs') {

  .split-content {
  }

}

// ---------------------------------------------------------------------------------------------------------------------
// print
// ---------------------------------------------------------------------------------------------------------------------

@media print {

  .split-content {
  }

}
