.image-gallery-extended {

  &__inner {
    display: grid;
    grid-gap: calc(var(--theme_outer-space) / 2);
    grid-auto-rows: px2rem(150);
    overflow: hidden;
    grid-template-columns: 1fr 1fr;

    border-radius: var(--theme_img-border-radius);

    @include media-breakpoint-up(lg) {
      grid-auto-rows: px2rem(250);
    }

    figure {
      margin-bottom: 0;
    }

    figure,
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      transform: scale(1);

      transition: transform 0.25s ease-in-out;
    }
  }

  &__inner &__image:hover,
  &__inner &__image:active,
  &__inner &__image:focus {
    img {
      transform: scale(1.05);
    }
  }

  &__title {
    margin-bottom: calc(var(--theme_outer-space) * 0.5);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include headlines {
      @include font-size($font-size-6);

      font-weight: $font-weight-bold;
    }
  }

  &__toggle {
    @include reset-button();
    --base-font-size: #{$font-size-2};

    transition: 0.25s ease-in-out color;
    font-size: 0;

    .fa {
      font-size: calc(var(--base-font-size) * 2);
      vertical-align: middle;
      transform: scale(1) rotate(90deg);
      margin-left: 1rem;

      transition: 0.25s ease-in-out transform;
    }

    &:hover,
    &:active,
    &:focus {
      color: map-get($theme-colors, 'primary');

      .fa {
        transform: scale(1.1) rotate(90deg);

      }
    }

    @include media-breakpoint-up('md') {
      font-size: var(--base-font-size);
    }
  }

  & + & {
    margin-top: var(--theme_outer-space);
  }

  @include media-breakpoint-up('md') {
    &--2 &__inner,
    &--4 &__inner {
      grid-template-columns: 1fr 1fr;

      img {
        max-height: px2rem(500);
      }
    }

    &--3 &__inner,
    &--5 &__inner,
    &--8 &__inner {
      grid-template-columns: repeat(14, 1fr);

      $grid-items: ('img-1','img-2','img-3','img-4','img-5','img-6', 'img-7', 'img-8');

      @for $i from 1 through length($grid-items) {
        & > :nth-child(#{$i}) {
          grid-area: unquote(nth($grid-items, $i));
        }
      }
    }

    // Darstellung bei 3 Bilder
    &--3 &__inner {
      grid-template-rows: px2rem(252);
      grid-template-areas: 'img-1 img-1 img-1 img-1 img-2 img-2 img-2 img-2 img-2 img-2 img-3 img-3 img-3 img-3';
    }

    // Darstellung bei 5 Bilder
    &--5 &__inner {
      grid-template-rows: px2rem(400) px2rem(300);
      grid-template-areas: 'img-1 img-1 img-1 img-1 img-1 img-1 img-1 img-2 img-2 img-2 img-2 img-2 img-2 img-2'
                           'img-3 img-3 img-3 img-3 img-4 img-4 img-4 img-4 img-4 img-4 img-5 img-5 img-5 img-5';
    }

    // Darstellung bei 8 Bilder
    &--8 &__inner {
      grid-template-rows: px2rem(400) px2rem(300) px2rem(300);
      grid-template-areas: 'img-1 img-1 img-1 img-1 img-1 img-1 img-1 img-2 img-2 img-2 img-2 img-2 img-2 img-2'
                           'img-3 img-3 img-3 img-3 img-4 img-4 img-4 img-4 img-4 img-4 img-5 img-5 img-5 img-5'
                           'img-6 img-6 img-6 img-6 img-7 img-7 img-7 img-7 img-7 img-7 img-8 img-8 img-8 img-8';
    }
  }

  &--oversize &__inner {
    @include media-breakpoint-up(xl) {
      width: calc(100% + var(--theme_overflow-size) * 2);
      margin-left: calc(var(--theme_overflow-size) * -1);
    }
  }

  &__overlay {
    height: calc(100vh - var(--theme_big-modal_top-bar-height));

    .swiper-wrapper {
      height: calc(100% - 3rem);
    }

    .swiper-pagination {
      bottom: 1rem;
    }

    .swiper-slide *,
    .swiper-slide figure img {
      height: 100%;
      width: 100%;

      object-fit: contain;
    }

    .swiper-button-prev {
      margin-left: 1rem;
    }

    .swiper-button-next {
      margin-right: 1rem;
    }
  }
}
