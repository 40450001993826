.content-video {
  &__iframe {
    padding-top: 56.25%;
    position: relative;

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &--oversize {
    @media (min-width: 90rem) {
      width: calc(100% + var(--theme_overflow-size) * 2);
      max-width: calc(100% + var(--theme_overflow-size) * 2);
      margin-left: calc(var(--theme_overflow-size) * -1);
    }
  }
}
