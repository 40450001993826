$headline-text-spacing: map-get($spacers, 5);
$button-text-spacing: map-get($spacers, 4);
$content-image-spacing: map-get($spacers, 5);
$text-bigleaf-spacing: map-get($spacers, 5) * 1.25;
$text-headline-spacing: map-get($spacers, 4);
$narrow-container-spacing: map-get($spacers, 5) * 2;
$container-spacing: calc(var(--theme_section-padding) * 1.25);

@mixin headline-spacing($space, $reduce-small: true) {
  @for $i from 1 through 6 {
    & + h#{$i},
    & + .h#{$i} {
      @if $reduce-small == false or $i <= 3 {
        margin-top: $space;
      } @else {
        margin-top: calc(#{$space} * #{divide(2, 3)});
      }
    }
  }
}

// Native sibling element spacing
p,
.content-text,
ul,
ol,
.hr-list-container {

  & + p,
  & + .content-text,
  & + ul,
  & + ol,
  & + ul.hr-list,
  & + .hr-list-container,
  & + .container,
  & + .container-fluid {
    margin-top: #{map-get($spacers, 4)};
  }

  // Remove last space below element in parent
  &:last-child {
    margin-bottom: 0;
  }

  // Space between element and headlines
  @include headline-spacing($headline-text-spacing);

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }

  & + .btn {
    margin-top: #{$button-text-spacing - $paragraph-margin-bottom};
  }

  // Increase button space a bit more, when last element
  & + .btn:last-child {
    margin-top: #{$button-text-spacing + 0.5rem - $paragraph-margin-bottom};
  }

  & + .big-leaf {
    margin-top: #{$text-bigleaf-spacing - $paragraph-margin-bottom};
  }
}

.content-heading {
  &:not(:first-child) {
    margin-top: $container-spacing;
  }

  & + * {
    margin-top: $text-headline-spacing;
  }

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$text-headline-spacing}); // This includes a calculation with the gutter width
  }
}

.row {
  & + & {
    margin-top: 0; // Result of `gutter-y - gutter-y`

    @include media-breakpoint-up(lg) {
      margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
    }
  }

  // Space between element and headlines
  @include headline-spacing($container-spacing, false);

  & + p,
  & + .content-text,
  & + ul,
  & + ol,
  & + ul.hr-list,
  & + .hr-list-container,
  & + .container,
  & + .container-fluid {
    margin-top: $container-spacing;
  }

  & + .content-heading {
    margin-top: $container-spacing !important;
  }

  & > * {
    & > p,
    & > .content-text,
    & > ul,
    & > ol,
    & > ul.hr-list,
    & > .hr-list-container {
      + .row {
        margin-top: calc((var(--bs-gutter-y) * -1) + #{$text-headline-spacing}); // This includes a calculation with the gutter width
      }
    }
  }
}

.container,
.container-fluid {
  & + & {
    margin-top: $container-spacing;
  }

  // Space between element and headlines
  @include headline-spacing($container-spacing);

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }
}

.content-image,
.image-gallery,
.slider-carousel,
.locations-catalog {
  // Figure has margin-bottom, so we have to remove it
  margin-bottom: 0;

  &:not(:first-child),
  & + * {
    margin-top: $content-image-spacing;
  }

  // Space between element and headlines
  @include headline-spacing($headline-text-spacing);

  & + .content-heading {
    margin-top: $headline-text-spacing;
  }

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }
}

.content-image {
  &--oversize {
    &:not(:first-child),
    & + * {
      margin-top: $container-spacing;
    }

    & + .row {
      margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
    }
  }
}

.btn {
  & + p,
  & + .content-text,
  & + ul,
  & + ol,
  & + .container,
  & + .container-fluid {
    margin-top: $button-text-spacing;
  }

  & + .content-heading {
    margin-top: $container-spacing;
  }

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }

  @include headline-spacing($container-spacing);
}

.big-leaf {
  &:not(:first-child) {
    margin-top: $container-spacing !important;
  }

  & + * {
    margin-top: $container-spacing;
  }

  & + .content-heading {
    margin-top: $container-spacing !important;
  }

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }
}

.narrow-container {
  &:not(:first-child),
  & + * {
    margin-top: $narrow-container-spacing !important; // Important, because we always want this large distance
  }

  & + .row {
    margin-top: calc((var(--bs-gutter-y) * -1) + #{$container-spacing}); // This includes a calculation with the gutter width
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    & + ul.hr-list,
    & + .hr-list-container {
      margin-top: $text-headline-spacing;
    }

    & + .container,
    & + .container-fluid {
      margin-top: $text-headline-spacing;
    }
  }
}
