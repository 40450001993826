.modal {
  &--blur.show {
    backdrop-filter: blur(30px);
  }

  &-close-button {
    line-height: 1;
    padding: calc(var(--theme_section-padding) * 0.33) var(--theme_outer-space);

    position: sticky;
    top: 0;
    z-index: 1000;

    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.66) 0%, rgba(60,60,60,0.13489145658263302) 70%, rgba(255,255,255,0) 100%);
  ;

    @include media-breakpoint-up(xl) {
      padding: var(--theme_big-modal_padding-y) var(--theme_big-modal_padding-x);
      width: 8rem;
      background: none;
    }

    button {
      @include reset-button;

      color: $white;
      font-size: var(--theme_big-modal_close-button-height);

      > svg {
        fill: $white;

        width: 3rem;
        height: 3rem;
      }
    }

    &--rotate button {
      > i,
      > svg {
        transform: rotate(45deg);
      }
    }
  }

  &-dialog {
    &--custom {
      color: white;

      pointer-events: all;
    }

    &.modal-fullscreen {
      height: auto;
    }
  }
}

.modal-backdrop {
  &--blur.show {
    opacity: 0.95;

    @supports (backdrop-filter: blur(24px)) {
      opacity: 0.75;
    }
  }
}
