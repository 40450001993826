.pagination-container {
  margin-top: calc(var(--theme_spacer-size) * 2);
  margin-bottom: calc(var(--theme_spacer-size) * 2);

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;

    .fa-arrow-right {
      font-size: percentage(divide(7, 8));
    }
  }

  .page-item {
    &.active {
      cursor: default;
    }

    &.disabled {
      opacity: divide(3, 8);
    }
  }
}