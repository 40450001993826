// ---------------------------------------------------------------------------------------------------------------------
// Variables
// ---------------------------------------------------------------------------------------------------------------------



// ---------------------------------------------------------------------------------------------------------------------
// xl
// ---------------------------------------------------------------------------------------------------------------------

.circle-list {

  @include break-word();

  list-style: none;
  padding: 0;
  line-height: divide(23, 16);
  font-size: $font-size-3;

  &--inactive-gray {
    color: map_get($colors, 'brand-gray');
  }

  &--indented {
    padding-left: 1rem;
  }

  &--reset-links {
    a {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $body-color;
        outline: none;
      }
    }
  }

  > li {
    padding-left: 1.25em;
    position: relative;

    &:before {
      @include fontello();
      content: '\f10c'; // circle-o
      color: map-get($theme-colors, 'primary');
      transform: scale(divide(19, 32));
      transform-origin: left center;
      position: absolute;
      left: 0;

      @each $background, $overwrites in $text-color-overwrites {
        @at-root .bg-#{$background} & {
          color: inherit;
        }
      }
    }

    + li {
      margin-top: 0.5em;
    }

    &.active {
      color: $primary;
      pointer-events: none;

      &:before {
        content: '\f111'; // circle
      }

      a {
        color: inherit !important;
      }
    }

  }
}


// ---------------------------------------------------------------------------------------------------------------------
// Backwards compatibility to index-list
// ---------------------------------------------------------------------------------------------------------------------

.index-list {
  @extend .circle-list;
  @extend .circle-list--inactive-gray;
  @extend .circle-list--reset-links;
}
