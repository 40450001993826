.content-group {
  --nav-space-x: #{$font-size-base * 1.5};
  --nav-space-y: #{$font-size-base * 0.5};

  &__nav-desktop {
    display: none;
    position: relative;
    background-color: transparent;
    padding: var(--theme_section-padding) var(--theme_outer-space) 0;
    z-index: $z-index-5;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: map_get($colors, 'brand-copper');
      padding: 0 var(--nav-space-x);
      font-weight: $font-weight-bold;

      &:hover,
      &:focus,
      &.active {
        outline: none;
        color: $primary;
      }

      &.active {
        pointer-events: none;
        font-weight: $font-weight-bold;

        .fa {
          color: inherit;
        }
      }

      .fa-external-link {
        margin-left: 0.5em;
        transform: translateY(-0.15rem);
      }
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      font-size: inherit;

      &:not(.dropdown-menu) {
        margin: calc(var(--nav-space-y) * -1) calc(var(--nav-space-x) * -1) 0;
      }
    }

    li {
      margin-top: var(--nav-space-y);
      border-left: 1px solid map_get($colors, 'brand-copper');

      &:first-child {
        border-left: none;
      }

      &.dots {
        color: map_get($colors, 'brand-copper');
      }
    }

    .content-group__nav-inner {
      width: 100%;
      max-width: map_get($container-max-widths, 'xl');
      margin: 0 auto;
    }

    .dots {
      position: relative;
      transition: opacity .5s;
      display: block;
      padding: 0 calc(var(--nav-space-x) * 0.5);

      &--hide {
        opacity: 0;
        pointer-events: none;
      }

      button {
        border: none;
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        color: map_get($colors, 'brand-copper');

        padding: 0 calc(var(--nav-space-x) * 0.5);

        &.show,
        &:hover {
          color: $primary;
        }

        &.show {
          --background-spread: 0.5rem;

          position: relative;
          z-index: 1;

          &:after {
            content: '';
            display: block;

            position: absolute;
            top: calc(var(--background-spread) * -1);
            right: 0;
            left: 0;
            bottom: calc(var(--background-spread) * -1);

            background: white;
            z-index: -1;
          }
        }
      }

      .fa-dot-3 {
        transform: rotate(90deg);
      }

      ul {
        --bs-position: end;

        padding: calc(var(--nav-space-x) / 2) 0;
        position: absolute;
        top: 2.5rem;
        right: 2rem;
        background-color: $white;
        box-shadow: 0 0 50px #00000029;
        display: none;

        &.show {
          display: block;
        }

        li {
          margin-top: 0;
          border: none;
        }

        a {
          color: $black;
          font-size: 0.85rem;
          white-space: nowrap;
          justify-content: flex-start;
          padding: calc(var(--nav-space-x) / 2.5) var(--nav-space-x);

          span {
            line-height: 1.125;
          }

          &:hover,
          &:focus,
          &.active {
            color: $primary;
          }
        }
      }
    }

    .swiper {
      &-slide {
        width: auto;

        &--hidden,
        &--mobile {
          display: none;
        }
      }
    }
  }

  &__tab {
    display: none;
  }

  &:not(.initialized) &__tab:first-child {
    display: block;
  }

  &.initialized {
    .content-group {
      &__tab {
        &.active {
          display: block;
        }
      }
    }
  }

  &--tabbed {
    .content-group {
      &__nav {
        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }
}
