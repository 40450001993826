@mixin bigleaf-background {
  position: relative;
  z-index: $z-index-3;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    margin: auto;
    width: calc(100% + (var(--theme_overflow-size)));
    max-width: 100vw;
    height: 100%;
    background-color: map_get($colors, 'brand-silver');
    transform: translateX(-50%);
    z-index: -1;

    @media (min-width: 1500px) {
      max-width: 95vw;
      width: calc(100% + (2 * var(--theme_overflow-size)));
    }

    // This is the ideal width to hide the bigleaf corners. Equally to bigleaf background width
    @media (min-width: 1440px) {
      border-radius: var(--theme_leaf-border-radius);
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
