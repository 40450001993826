.blog-container {
 .container {
   max-width: calc(#{map_get($container-deviant-sizes, 'md')} + (var(--theme_outer-space) * 2));
   padding-left: 0;
   padding-right: 0;
 }

  &-end {
    &__share-area {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: var(--theme_section-padding);
      margin-top: -1rem;
      margin-left: -1rem;

      & > * {
        margin-top: 1rem;
        margin-left: 1rem;
      }
    }
  }
}
