.pac {
  &-container {
    border: solid 1px white;
    margin-top: -0.1rem;
  }

  &-item {
    padding: 0.25rem 0.75rem;
  }
}
