.times-table {
  display: table;
  table-layout: fixed;

  width: 100%;

  &__row {
    display: table-row;
  }

  &__cell {
    display: table-cell;
    width: #{100% * divide(12, 16)};

    &:first-child {
      width: #{100% * divide(4, 16)};
      padding-right: map-get($spacers, 2);
    }
  }
}
